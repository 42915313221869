import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private apiData = new BehaviorSubject<any>(null);
  public apiData$ = this.apiData.asObservable();

  private songData = new BehaviorSubject<any>(null);
  public songData$ = this.songData.asObservable();

  private newState = new BehaviorSubject<any>(null);
  public newState$ = this.newState.asObservable();

  private songStatus = new BehaviorSubject<any>(null);
  public songState$ = this.songStatus.asObservable();

  private opencloseplayer = new BehaviorSubject<any>(null);
  public opencloseplayer$ = this.opencloseplayer.asObservable();

  private queueSongClear = new BehaviorSubject<any>(null);
  public queueSongClear$ = this.queueSongClear.asObservable();

  private queuePopUpClose = new BehaviorSubject<any>(null);
  public queuePopUpClose$ = this.queuePopUpClose.asObservable();

  public returnCurrentplayingSongIdVar: any;
  private currentplayingSongIdVar = new BehaviorSubject<any>(null);
  public currentplayingSongIdVar$ = this.currentplayingSongIdVar.asObservable();

  public returnCurrentplayingRadioIdVar: any;
  private currentplayingRadioIdVar = new BehaviorSubject<any>(null);
  public currentplayingRadioIdVar$ = this.currentplayingRadioIdVar.asObservable();

  private setFavVar = new BehaviorSubject<any>(null);
  public setFavVar$ = this.setFavVar.asObservable();

  private playerProgressVar = new BehaviorSubject<any>(null);
  public playerProgressVar$ = this.playerProgressVar.asObservable();

  public player;
  public queueData;
  constructor() { }
  setData(data) {
    this.apiData.next(data);
  }
  setStatus(data) {
    this.songData.next(data);
  }
  newStateSong(status) {
    this.newState.next(status);
  }
  songState(status) {
    this.songStatus.next(status);
  }
  queueClear(status) {
    this.queueSongClear.next(status);
  }
  queueClose(status) {
    this.queuePopUpClose.next(status);
  }
  setCurrentPlayingSongId(id) {
    this.currentplayingSongIdVar.next(id);
    this.returnCurrentplayingSongIdVar = id;
  }

  getCurrentPlayingSongId() {
    return this.returnCurrentplayingSongIdVar;
  }

  setCurrentPlayingRadioId(id) {
    this.currentplayingRadioIdVar.next(id);
    this.returnCurrentplayingRadioIdVar = id;
  }

  getCurrentPlayingRadioId() {
    return this.returnCurrentplayingRadioIdVar;
  }

  triggerSongFavourite(data) {
    this.setFavVar.next(data);
  }

  updatePlayerProgress(progress) {
    this.playerProgressVar.next(progress);
  }

  setPlayerVariable(data) {
    this.player = data;
  }

  getPlayerVariable() {
    return this.player;
  }

  setQueueData(data) {
    this.queueData = data;
  }

  getQueueData() {
    return this.queueData;
  }

  openOrClose(data){
    return this.opencloseplayer.next(data);
  }
}
