import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor() { }
  // Observable User details
  private currentVideo = new BehaviorSubject<any>('');
  currVideo = this.currentVideo.asObservable();
  private videoPaymentDataVar = new BehaviorSubject<any>('');
  videoPaymentInfo = this.videoPaymentDataVar.asObservable();

  private adminPlaylistSlug = new BehaviorSubject<any>('');
  currentAdminPlaylistSlug = this.adminPlaylistSlug.asObservable();

  // Observable popup details
  private popup = new BehaviorSubject<any>('close');
  display = this.popup.asObservable();

  // user details
  videoData(data: any) {
    this.currentVideo.next(data);
  }

  videoPaymentData(data: any) {
    this.videoPaymentDataVar.next(data);
  }

  setAdminPlaylistSlug(data: string) {
    this.adminPlaylistSlug.next(data);
  }

  // login, register and forget password popup
  displayPopup(data: any) {
    this.popup.next(data);
  }
}
