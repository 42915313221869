import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import disableScroll from 'disable-scroll';
import {
  AuthService,
  SocialUser,
  GoogleLoginProvider,
  FacebookLoginProvider
 } from 'angularx-social-login';
import { AuthService as auth } from '../../core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';

declare var $: any;
@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {

  user: SocialUser;
  login = false;
  @Output() updateSubscription = new EventEmitter;
  constructor(
    private authService: AuthService,
    private authLogin: auth,
    private toasterService: ToastrService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() { }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
    .then((response) => {
     this.socailLogin();
    }).catch((error) => {
    });

  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
    .then((response) => {
    this.socailLogin();
    }).catch((error) => {
    });

  }

  signOut(): void {
    this.authService.signOut();
  }

  socailLogin(): void {
    this.login = false;
    this.authService.authState.subscribe((user) => {
      this.user = JSON.parse(JSON.stringify(user));
      if (this.user) {
        if (this.user.id !== undefined && !this.login) {
            this.authLogin.login(this.user)
            .subscribe(
              data => {
                if (data['statusCode'] === 200 && data['error'] === false) {
                  this.updateSubscription.emit(data['response'].is_subscribed);
                  this.login = true;
                  this.userService.displayPopup('close');
                  localStorage.removeItem('audioPage');
                  if (document.getElementById('Signin')) {
                     document.getElementById('Signin').style.display = 'none';
                  }
                  if (data['response'].name && !localStorage.getItem('currentUser')) {
                    console.log(2);
                    const is_verify = data['response'].is_phone_verified;
                    if (!is_verify) {
                       this.router.navigate(['/profile']);
                    }
                    const userData = this.authLogin.encryption(data);
                    this.asyncLocalStorage.setItem('currentUser', userData);
                    $('.lang-dropdown ul li')[data['response'].preferred_language - 1].click();
                    $('#lang-toggle').click();
                    this.router.navigate(['/loading']);

                    /*  const userData = this.authLogin.encryption(data);
                      this.asyncLocalStorage.setItem('currentUser', userData).then(() => {
                        if (window.location.pathname.indexOf('video') !== -1) {
                          window.location.reload();
                        } else {
                          this.userService.nextPage.subscribe(
                          nextUrl => {
                           this.router.navigate(nextUrl);
                          });
                        }
                      });*/
                    // window.location.reload();
                  }
                  this.authService.signOut();
                  }
            },
            error => {
              document.getElementById('Signin').style.display = 'block';
              if (this.user.email) {
                 this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
              } else {
                this.toasterService.error('', 'Update your email id in social network');
              }
            }
          );

        }
      }
    });
  }
  // tslint:disable-next-line:member-ordering
  asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};
}
