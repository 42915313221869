import { Component, EventEmitter, Input, OnInit, Output, HostListener, ElementRef } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { VideoModel } from '../../../../modules/video-detail/video.model';
import { Router } from '@angular/router';
import { UserService } from '../../../../core/services/user.service';
import { PlayerService } from '../../../../core/services/player.service';
import { VideoShareService } from '../../../../core/services/video-share.service';
import { PlaylistAddService } from '../../../../core/services/playlist-add.service';
import { AuthService } from '../../../../core/auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import disableScroll from 'disable-scroll';
import { trigger, transition, style, animate } from '@angular/animations';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-search-video-card',
  templateUrl: './search-video-card.component.html',
  styleUrls: ['./search-video-card.component.scss'],
  animations: [
    trigger('videoCardAnimation', [
      transition(':enter', [
        style({ opacity: 0.2 }),
        animate('700ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ],
})
export class SearchSearchVideoCardComponent implements OnInit {
  @Input() video: any = {};
  @Input() history: any;
  @Input() live: boolean;
  @Input() type: string;
  @Input() category: string;
  @Input() playlist_id: any;
  @Input() playlist_name: any;
  @Input() currentlyPlaying: any;
  @Output() favouriteChange = new EventEmitter();
  @Output() historyChange = new EventEmitter();
  @Output() playlistChange = new EventEmitter();
  @Output() dragEvent = new EventEmitter<string>();
  @Output() videoCardClicked = new EventEmitter;
  loggedIn: any = {};
  tostar: any = {};
  loggedTrue: Boolean = false;
  showOption: any = false;
  showFlag: Boolean = false;
  shareDrop: any;
  videoDetails: any;
  playlist: any = [];
  loading: boolean;
  shareFlag: boolean;
  flag: boolean;
  final: boolean;
  submitted: boolean;
  result: boolean;
  errors: any;
  success: any;
  utilityService: any;
  buttonText: string;
  playlistForm: any;
  playlistFlag: boolean;
  addZindex = false;
  slidItemClass = false;
  currentSiteLang: string;
  allowedPremium_video: false;
  @HostListener('document:click', ['$event'])
  clickOut() {
    if (this.shareDrop !== undefined) {
      if (!this.eRef.nativeElement.contains(event.target)) {
        disableScroll.off();
        this.showOption = false;
        this.shareDrop = undefined;
        this.addZindex = false;
      }
    }
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private userService: UserService,
    private videoShareService: VideoShareService,
    private playerService: PlayerService,
    private eRef: ElementRef,
    private playlistAddService: PlaylistAddService,
    private meta: Meta,
    private authService: AuthService,
  ) {
    $(function () {
      $('.slider-img img').on('error', function () {
        $(this).attr({
          'src': '/assets/images/default-img.png'
        });
      });
    });
    const userData = this.authService.decryption(localStorage.getItem('currentUser'));
    if (userData['response'].allow_premium_video) {
      this.allowedPremium_video = userData['response'].allow_premium_video;
    }
  }
  ngOnInit() {
    this.slidItemClass = this.type === 'live';
    this.videoDetails = this.video;
    this.playlistForm = new FormGroup({
      playlist_name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\s]*$')])
    });
    if (this.video.is_shorts == 1) {
      this.video.thumbnail_image = (this.video.poster_image !== null) ? this.video.poster_image : (this.video.thumbnail_image !== null) ? this.video.thumbnail_image : 'https://d1gqth3loa1oxy.cloudfront.net/common/blank_new.png';
    }
    this.video.thumbnail_image = (this.video.thumbnail_image.length > 0) ? this.video.thumbnail_image : 'https://d1gqth3loa1oxy.cloudfront.net/common/blank_new.png';
    this.live = (this.live) ? true : false;
    if (localStorage.getItem('currentUser')) {
      this.loggedTrue = true;
    }
    this.currentSiteLang = (localStorage.getItem('currentLanguage')) ? localStorage.getItem('currentLanguage') : 'en';
  }

  showOptions(event) {
    if (this.showOption === false) {
      disableScroll.on(undefined, { disableWheel: true, disableScroll: false, disableKeys: false });
    }
    this.addZindex = true;
    this.shareDrop = event.target;
    this.showOption = !this.showOption;
  }

  openShareVideo() {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    this.videoShareService.displayPopup(this.video);
  }
  openPlalistPopup() {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    this.playlistAddService.displayPopup(this.video);
  }
  favourite(video: VideoModel, e) {
    if (sessionStorage.getItem('tostar')) {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': JSON.parse(sessionStorage.getItem('tostar')).tostarTime
      };
    } else {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': 0
      };
    }
    sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
    e.stopPropagation();
    this.loggedIn = localStorage.getItem('currentUser');
    if (this.loggedIn) {
      const favourite = video.is_favourite;
      if (favourite) {
        this.apiService.callPostAPI('v2/favourite', { video_slug: video.slug }, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  this.apiService.successResponse(data, '');
                }
                this.video.is_favourite = favourite;
                this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              this.favouriteChange.emit(this.video);
            });
      } else {
        this.apiService.callDeleteAPI('v2/favourite?video_slug=' + video.slug, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  this.apiService.successResponse(data, '');
                }
                this.video.is_favourite = favourite;
                this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              this.favouriteChange.emit(this.video);
            });
      }

    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup('sign-in');
    }
  }

  removeHistory(video: VideoModel, e) {
    e.stopPropagation();
    this.apiService.callPostAPI('v2/clear_recent_view', { video_id: video.slug })
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.apiService.successResponse(data, '');
          }
        },
        error => {
          this.apiService.failureResponse(error, false);
        });
    this.historyChange.emit(this.video);
  }

  videoClick(video, imageClick) {
    debugger
    if (imageClick === 'image') {
      disableScroll.off();
      this.showOption = false;
      this.shareDrop = undefined;
      this.addZindex = false;
      this.playerService.videoData(video);
      if (video.hasOwnProperty('is_live') && video.is_live === 0) {
        if (video.is_premium === 1) {
          if (video.is_subscribed === 1) {
            if (!this.allowedPremium_video) {
              this.videoShareService.setRestrictVideoPlay(video);
            } else {
              if (video.slug) {
                if (this.type === 'playlist') {
                  this.router.navigate(['watch/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
                } else {
                  this.router.navigate(['watch/' + video.slug]);
                }
              }
            }
          } else {
            if (video.slug) {
              if (this.type === 'playlist') {
                this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
              } else {
                this.router.navigate(['video/' + video.slug]);
              }
            }
          }
        } else {
          if (video.slug) {
            if (this.type === 'playlist') {
              if (!localStorage.getItem('currentUser')) {
                this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
              } else {
                if (!this.allowedPremium_video) {
                  this.videoShareService.setRestrictVideoPlay(video);
                } else {
                  this.router.navigate(['watch/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
                }
              }
            } else {
              if (!localStorage.getItem('currentUser')) {
                this.router.navigate(['video/' + video.slug]);
              } else {
                /* if (!this.allowedPremium_video) {
                  this.videoShareService.setRestrictVideoPlay(video);
                } else { */
                this.router.navigate(['watch/' + video.slug]);
                //}
              }
            }
          }
        }
      } else {
        if (video.is_premium === 1) {
          if (this.type === 'playlist') {
            this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
          } else {
            this.router.navigate(['video/' + video.slug]);
          }
        } else {
          if (!this.allowedPremium_video) {
            this.videoShareService.setRestrictVideoPlay(video);
          } else {
            this.router.navigate(['watch/' + video.slug]);
          }
        }
      }
    } else {
      disableScroll.off();
      this.showOption = false;
      this.shareDrop = undefined;
      this.addZindex = false;
      this.playerService.videoData(video);
      if (video.slug) {
        if (this.type === 'playlist') {
          this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
        } else {
          this.router.navigate(['video/' + video.slug]);
        }
      }
    }
  }


  removeFromPlaylist(video: VideoModel, e) {
    e.stopPropagation();
    this.apiService.callDeleteAPI('v2/create_playlist_videos?playlist_id=' + this.playlist_id + '&video_id=' + this.video.slug, environment.userActionApiUrl)
      .subscribe(data => {
        this.apiService.successResponse(data);
      }, error => {
        this.apiService.failureResponse(error);
      });
    this.playlistChange.emit(video);
  }

  videoCardFullClick(video) {
    this.videoCardClicked.emit(video);
  }

}


