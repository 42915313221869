import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { environment } from 'src/environments/environment';
import * as Highcharts from 'highcharts';
import {Subject} from 'rxjs/Subject';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})

export class AnalyticsComponent implements OnInit {
  @Input() changing: Subject<boolean>;
  @Input() chartTitle: any;
  @Input() yesterday: any;
  @Input() today: any;
  analyticsData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: any = 'chart'; // optional string, defaults to 'chart'
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: String(this.chartTitle)
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: ''
      },
      stackLabels: {
        enabled: true,
        style: {
          color: 'black',
          fontSize: '30px',
          fontWeight: '100'
        },
        formatter: function () {
          return this.total + " min.";
        }
      }
    },
    xAxis: {
      categories: [String(this.yesterday),String(this.today)]
    },
    series: [{
      data: [],
      type: "column",
    }]
  };
  updateFlag: any = false; // optional boolean
  oneToOneFlag: any = false; // optional boolean, defaults to false
  runOutsideAngular: any = false; // optional boolean, defaults to false

  // chart two
  fifteenDayChart: typeof Highcharts = Highcharts; // required
  fifteenDayChartConstructor: any = 'chart'; // optional string, defaults to 'chart'
  fifteenDayChartOptions: Highcharts.Options = {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: ''
      },
    },
    xAxis: {
      categories: ['yesterday', 'today']
    },
    series: [{
      data: [],
      type: "column",
    }]
  };
  fifteenDayUpdateFlag: any = false; // optional boolean
  fifteenDayOneToOneFlag: any = false; // optional boolean, defaults to false
  fifteenDayRunOutsideAngularFlag: any = false;
  show: any = false;
  constructor(
    private apiService: ApiService,
    private toasterService: ToastrService) {
      this.getAnalytics();
  }
  ngOnInit() {
    this.changing.subscribe(v => {
      this.getAnalytics(true, '?is_show=true');
   });
  }

  getAnalytics = (showMessage = false, queryParam = '') => {
    if (localStorage.getItem('currentUser') && !sessionStorage.getItem('show_analytics')) {
      this.apiService
        .callGetAPI('v2/customer-analytics' + queryParam, environment.analyticsApiUrl)
        .subscribe(
          async(res) => {
            console.log(res);
            if (!res['response'].analytics.show_analytics) {
              if(showMessage != false){
                this.toasterService.success('Please Watch any Video to see the Analytics');
              }
              this.show = false;
              return false;
            } else {
              sessionStorage.setItem('show_analytics', '1');
              this.show = true;
            }
            this.analyticsData = await res['response'].analytics.video_watched_time.map((d, i) => {
              if (i === 0) {
                return { y: d.duration, color: 'orange' };
              } else {
                return { y: d.duration, color: '#f9782c' };
              }
            });
            this.analyticsData.reverse();
            console.log('this.analyticsData', this.analyticsData);
            // this.updateFlag = true;
            let y = (this.analyticsData[13]['y'] === 0 && this.analyticsData[14]['y'] === 0)?250:-15;
            console.log('y',y, this.analyticsData[13]['y'],this.analyticsData[14]['y']);
            this.chartOptions = {
              chart: {
                type: 'column',
                width: 650,
                height: 720,
              },
              title: {
                text: '',
                margin: 0,
                style: { "display": "none" }
                // text:  String(this.chartTitle),
                // style: { "color": "black", "fontSize": "65px" }
              }, 
              plotOptions: {
                column: {
                  stacking: 'normal'
                }
              },
              tooltip: {
                enabled: false,
              },
              yAxis: {
                top:10, //50,
                allowDecimals: false,
                // min: 0,
                title: {
                  text: ''
                },
                stackLabels: {
                  enabled: true,
                  style: {
                    color: 'black',
                    fontSize: '40px',
                    fontWeight: '100',
                  },
                  y: y,
                  formatter: function () {
                    // if (this.total) {
                      console.log('total',this.total);
                      return this.total + " min.";
                    // } else {
                    //   return '';
                    // }
                  }
                },
                gridLineColor: 'white',
                minorGridLineColor: 'white',
                minorTickInterval: 'auto',
                labels: {
                  enabled: false
                }
              },
              xAxis: {
                categories: [String(this.yesterday),String(this.today)],
                className: 'x-axis-title',
                top: 30,
                lineColor: 'white',
              },
              credits: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              series: [{
                data: [this.analyticsData[13],this.analyticsData[14]],// [{ y: 20, color: '#f9782c' },{ y: 500, color: '#f9782c' }],
                type: "column",
                borderRadius: 2,
                borderWidth: 2,
                borderColor: 'black',
                maxPointWidth: 800
              }]
            };
            this.fifteenDayChartOptions = {
              chart: {
                type: 'column',
                backgroundColor: '#0c1f37',
                width: 7500,
                height: 1500,
              },
              title: {
                text: '',
                margin: 0,
                style: { "display": "none" }
              },
              plotOptions: {
                column: {
                  stacking: 'normal'
                }
              },
              tooltip: {
                enabled: false,
              },
              yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                  text: ''
                },
                gridLineColor: '#0c1f37',
                minorGridLineColor: '#0c1f37',
                minorTickInterval: 'auto',
                labels: {
                  enabled: false
                }
              },
              xAxis: {
                labels: {
                  enabled: false
                },
                className: 'x-axis-title-two'
              },
              credits: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              series: [{
                data: this.analyticsData, // [5, 3],
                type: "column",
                borderRadius: 2,
                borderWidth: 2,
                borderColor: 'black',
                minPointLength: 50
              }]
            };
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }

  doNotShow = () => {
    console.log('doNotShow');
    this.apiService
      .callPostAPI('v2/customer-analytics/do-not-show-again', {}, environment.analyticsApiUrl)
      .subscribe(
        (res) => {
          this.show = false;
        },
        (error) => {
          this.apiService.failureResponse(error);
        });
  }

  closeAnalytics = () => {
    this.show = false;
  }

}
