import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../core/services/api.service';
import { UserService } from '../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  url: any = [];
  user: any = {};
  submitted = false;
  emailMinLength: any = 3;
  emailMaxLength: any = 254;
  LoggedIn = localStorage.getItem('currentUser');
  error: any = {};
  buttonText;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private userService: UserService,
    private translate: TranslateService
  ) {
    this.translate.get('FORGET_PASSWORD.SEND_EMAIL').subscribe(res => {
      this.buttonText = res;
    });
  }

  ngOnInit() {
    if (this.LoggedIn) {
      this.router.navigate(['home']);
    }
  }

  forgotPassword() {
    this.submitted = true;
    this.translate.get('FORGET_PASSWORD.SENDING').subscribe(res => {
      this.buttonText = res;
    });
    this.url.redirect = 'sign-in';
    this.apiService.callPostAPI('v2/auth/forgotpassword', {email: this.user.email}, environment.userApiUrl)
      .subscribe(
        data => {
          this.submitted = false;
          this.translate.get('FORGET_PASSWORD.SEND_EMAIL').subscribe(res => {
            this.buttonText = res;
          });
          this.userService.displayPopup('sign-in');
          this.apiService.successResponse(data);

        },
        error => {
          this.submitted = false;
          this.translate.get('FORGET_PASSWORD.SEND_EMAIL').subscribe(res => {
            this.buttonText = res;
          });
          this.error = this.apiService.failureResponse(error);
        });
  }

  onChange() {
    this.error = {};
  }

  onClose() {
    if (this.route.snapshot['_routerState'].url === '/forget-password') {
      this.router.navigate(['home']);
    }
    this.userService.displayPopup('close');
  }

  onClick() {
      this.userService.displayPopup('sign-in');
  }

}
