import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiService } from '../../core/services/api.service';
import { VideoShareService } from '../../core/services/video-share.service';
import { PlayerService } from '../../core/services/player.service';
import disableScroll from 'disable-scroll';
import { environment } from 'src/environments/environment';
import { UserService } from '../../core/services/user.service';
import { AuthService } from '../../core/auth/auth.service';
import { Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-admin-playlist',
  templateUrl: './admin-playlist.component.html',
  styleUrls: ['./admin-playlist.component.scss']
})
export class AdminPlaylistComponent implements OnInit {
  params: string;
  playlistData: any;
  playlistVideos: any = [];
  playlistvideosCount = 0;
  flag = false;
  final = false;
  page = 0;
  loader = false;
  playlistListingData: any = [];
  isDetailView = false;
  isListingView = false;
  loadmore = false;
  loadmorePlaylistVideos = false;
  loadingSlider: any = [];
  allowedPremium_video: false;
  constructor(
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private videoShareService: VideoShareService,
    private playerService: PlayerService,
    private userService: UserService,
    private authService: AuthService,
  ) {
    const userData = this.authService.decryption(localStorage.getItem('currentUser'));
    if (userData['response'].allow_premium_video) {
      this.allowedPremium_video = userData['response'].allow_premium_video;
    }
  }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.route.params.subscribe(params => {
      this.router.navigated = false;
      this.params = this.route.snapshot.params.slug;
      if (this.params && this.params.indexOf('?') && this.params.indexOf('?') !== -1) {
        const params1 = this.params.substring(0, this.params.indexOf('?'));
        this.router.navigate(['playlist/' + params1]);
      } else if (this.params === undefined) {
        this.getAllPlaylists();
      } else {
        this.getPlaylistData();
      }
    });
    this.loader = true;
    this.loadingSlider.data = [];
    for (let i = 0; i <= 6; i++) {
      this.loadingSlider.data.push({ 'thumbnail_image': environment.defaultImage });
    }
    this.playerService.display.subscribe(data => {
      if (data === 'sign-in') {
        this.userService.displayPopup(data);
      } else {
        this.userService.displayPopup('close');
      }
    });
  }
  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll() {
  //   if ($(window).scrollTop() + $(window).height() > $('footer').offset().top && this.final === false && this.flag === false) {
  //       this.flag = true;
  //       (this.params === undefined) ? this.getAllPlaylists() : this.getPlaylistData();
  //   }
  // }
  getAllPlaylists() {
    this.isDetailView = false;
    this.isListingView = true;
    this.page = this.page + 1;
    const url = 'v2/browse_admin_playlist';
    this.apiService.callGetAPI(url + '?page=' + this.page)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.playlistListingData = this.playlistListingData.concat(data['response'].playlists.data);
            this.final = (data['response'].playlists.next_page_url === null) ? true : false;
            this.loadmore = (data['response'].playlists.next_page_url === null) ? false : true;
            this.flag = false;
          }
        }, error => {
          this.apiService.failureResponse(error, true);
        });

  }
  getPlaylistData() {
    this.isDetailView = true;
    this.isListingView = false;
    this.page = this.page + 1;
    const url = 'v2/playlist_detail/';
    this.params = this.route.snapshot.params.slug;
    this.apiService.callGetAPI(url + this.params + '?page=' + this.page)
      .subscribe(
        data => {
          this.loader = false;
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.playlistData = data['response'].playlist_info;
            this.playlistvideosCount = data['response'].playlist_videos_total_count;
            this.playlistVideos = (this.playlistVideos === undefined)
              ? data['response'].playlist_info.playlist_videos.data
              : this.playlistVideos.concat(data['response'].playlist_info.playlist_videos.data);
            this.final = (data['response'].playlist_info.playlist_videos.next_page_url === null) ? true : false;
            this.loadmorePlaylistVideos = (data['response'].playlist_info.playlist_videos.next_page_url === null) ? false : true;
            this.flag = false;
          }
        }, error => {
          this.apiService.failureResponse(error, true);
        });
  }
  /**
   * Method to share the popup in social networks
   */
  openSharePopup() {
    this.videoShareService.setAdminPlaylistShareData(this.playlistData);
  }
  openRestrictVideoPopup() {
    this.videoShareService.setRestrictVideoPlay(this.playlistData);
  }
  detailPageBack() {
    this._location.back();
  }
  playlistPlayAll() {
    if (!localStorage.getItem('currentUser')) {
      this.userService.displayPopup('sign-in');
    } else {
      if (!this.allowedPremium_video) {
        this.videoShareService.setRestrictVideoPlay(this.playlistData);
      } else {
        this.router.navigate(['watch/' + this.playlistVideos[0].slug], { queryParams: { playlist_id: this.params } });
      }
    }
  }
}
