export class SubscriptionModel {
  name: string;
  amount: number;
  description: string;
  slug: string;
  type: string;
  duration: string;
  is_subscribe: boolean;
}

export class PaymentModel {
  subscription_plan_id: string;
  payment_method_id: number;
}

export class CardModel {
  id: number;
  name: string;
  card_number: number;
  cvv: number;
  month: number;
  year: number;
  type: string;
  user_id: number;
}
