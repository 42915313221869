import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-shorts-playlist-card',
  templateUrl: './admin-shorts-playlist-card.component.html',
  styleUrls: ['./admin-shorts-playlist-card.component.scss'],
  styles: [`
    .main-dark .contus-slider .slider-img img,
    .main-dark .cs-slider .slider-img img,
    .main-dark .history-container .slider-img img,
    .main-dark .genre-list .slider-img img {
        max-height: 300px !important;
    }

    .main-dark .itemTile {
        margin-right: 10px !important;
    }

    .main-dark .cs-slider .slider-item1 {
        width: 200px !important;
        height: 40px !important;
        overflow: hidden !important;

    }

  `],
})
export class AdminShortsPlaylistCardComponent implements OnInit {
  @Input() shorts: any = {};
  @Input() type: string;
  slidItemClass = false;
  constructor(private router: Router) { }

  ngOnInit() {
  }
  playlistClick(shortsData) {
    if (shortsData.slug == 'radio') {
      this.router.navigate(['radio']);
    } else {
      this.router.navigate(['video/' + shortsData.slug]);
    }
  }
}
