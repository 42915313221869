import { Component, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../core/services/api.service';
import videojs from '../../../assets/js/play.es.js';
import { PlayerService } from '../../core/services/player.service';
import { ConnectionService } from 'ng-connection-service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

declare var $: any;

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss']
})
export class EmbedComponent implements OnInit, OnDestroy, AfterViewInit {

  enableSubscribe = false;
  isPlaying = false;
  player: any;
  time = 0;
  decrypted: any;
  encrypted: any;

  overlayContent: any;
  status = 'ONLINE';
  isConnected = true;
  newtworkStatus: any;
  setVideo: Boolean = true;
  params: any;
  video: any;
  interVal: any;
  fullParams: any;
  videos: any;
  geoStatus = true;
  plainText: any;
  cipherText: any;

  notSubscribed: Boolean = false;
  href: any;
  payMethod: any;
  constructor(
    private route: ActivatedRoute,
    private playerService: PlayerService,
    private connectionService: ConnectionService,
    private router: Router,
    private apiService: ApiService
  ) {

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = 'ONLINE';
      } else {
        this.status = 'OFFLINE';
        this.newtworkStatus = document.getElementById('online-toast');
        document.getElementById('videos').appendChild(this.newtworkStatus);
      }
    });
    this.route.params.subscribe(params => {
      this.fullParams = params;
      this.params = params['video_id'];
      this.getVideos();

    });
  }

  ngOnInit() {
    const bodyvar = document.getElementsByTagName('body')[0];
    bodyvar.style.paddingTop = '0px';
    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'light') {
        document.body.classList.add('main-light');
      }
      if (localStorage.getItem('theme') === 'dark') {
        document.body.classList.add('main-dark');
      }
    } else {
      document.body.classList.add('main-dark');
      localStorage.setItem('theme', 'dark');
    }

  }
  ngAfterViewInit(): void {
    this.setPlayerConfig();
  }
  getVideos() {
    const url = 'v2/watchvideo/';
    this.apiService.callGetAPI(url + this.fullParams['video_id'])
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.videos = data['response'].videos;
            if (!this.videos.is_restricted) {
            this.decrypt();
            this.geoStatus = false;
            // this.apiService.callPostAPI('v2/videoanalytics', {'video_slug': this.videos.videos.slug}, environment.analyticsApiUrl)
            // .subscribe();
            }
          }
          if (this.videos.is_premium === 1) {
            if (this.videos.is_subscribed === 0) {
              this.notSubscribed = true;
              this.href = environment.domain + 'subscription';
            } else {
              this.notSubscribed = false;
              this.setPlayer();
            }
          } else {
            this.notSubscribed = false;
            this.setPlayer();
          }
        },
        error => {
          if (error.error.response.data.payMethod === 'SVOD') {
            this.payMethod = 1;
            this.notSubscribed = true;
            this.href = environment.domain + 'subscription';
          } else if (error.error.response.data.payMethod === 'TVOD') {
            this.payMethod = 2;
            this.notSubscribed = false;
            this.href = environment.domain + 'video/' + error.error.response.data.videoSlug;
          } else {
            this.payMethod = 1;
            this.notSubscribed = true;
            this.href = environment.domain + 'subscription';
          }
        });
  }

  setPlayerConfig() {
    const getVideo = document.getElementById('videos');
    videojs.Hls.xhr.beforeRequest = (options) => {
      options.headers = [];
      if (this.videos.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.player = videojs(getVideo, {
      'playbackRates': [0.25, 0.5, 1, 1.25, 1.5, 2],
      'controls': true,
      'preload': 'auto',
      'fluid': true,
      'autoplay': true,
      'html5': {
        'nativeAudioTracks': false,
        'nativeVideoTracks': false,
        'nativeTextTracks': false,
        'hls': {
          'overrideNative': true,
          'customDrm': {
            // tslint:disable-next-line:max-line-length
            'keys': 'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ',
            // "uri": "http://d1xxzl3j6dvosn.cloudfront.net/costarica/encrypted.key",
          }
        },
      },
      'plugins': {
        'hlsQualitySelector': {},
        'keyboardShortCuts': {}
      }
    });
  }
  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }
  binaryToHex(num) {

    let str = '',
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
    // return parseInt(num, 2).toString(16);
  }
  d2h(d) {
    return d.toString(16);
  }
  decrypt() {

    if (typeof this.videos.passphrase !== 'undefined') {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(this.videos.passphrase)
      });
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams, CryptoJS.enc.Hex.parse(environment.crypto_key), {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC,
        });
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      this.incrementInterVal();
    }
  }
  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex, CryptoJS.enc.Hex.parse(environment.crypto_key), {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC,
      });
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }
  incrementInterVal() {
    this.interVal = setInterval(function () {
      this.plainText += 1;
    }.bind(this), 1000);
    this.setPlayerConfig();
  }

  setPlayer() {
    this.newtworkStatus = document.getElementById('online-toast');
    document.getElementsByClassName('play-text-track-display')[0].innerHTML = '<div>' + this.videos.title + '</div>';
    document.getElementById('videos').appendChild(this.newtworkStatus);
    $('#ogImage').attr('content', this.videos.poster_image);
    this.player.on('play', () => {
      //  const socket = new WebSocket(environment.socketUrl);
      //   socket.onopen = (event) => {
      //     socket.send('1');
      //   };
    });
    this.player.play();
    this.player.autoplay(true);
    this.player.src({
      src: this.videos.hls_playlist_url,
      type: 'application/x-mpegURL'
    });
    /** Check last video session */
    const session = JSON.parse(sessionStorage.getItem('videos'));
    if (session && session['slug'] === this.videos.slug) {
      this.player.currentTime(session['currentTime']);
    } else {
      this.player.currentTime(0);
    }
    this.player.on('pause', () => {
      // const socket = new WebSocket(environment.socketUrl);
      // socket.onopen = (event) => {
      //   socket.send('0');
      // };
    });
    /** Video playing */
    setInterval(function () {
      if ($('.play-control-bar').css('opacity') === '1') {
        $('.back_btn').css('display', 'block');
        $('.movie-title').css('display', 'block');
      } else {
        $('.back_btn').css('display', 'none');
        $('.movie-title').css('display', 'none');
      }
    }.bind(this), 1);
    /** Video current time update */
    this.player.on('timeupdate', () => {
      this.time = this.player.currentTime();
      this.videos.currentTime = this.time;
      sessionStorage.setItem('videos', JSON.stringify(this.videos));
    });
    /** Video Error */
    this.player.on('error', (event) => {
      //  const socket = new WebSocket(environment.socketUrl);
      // socket.onopen = (event) => {
      //   socket.send('0');
      // };
    });

    /** Video End */
    this.player.on('ended', () => {
      // const socket = new WebSocket(environment.socketUrl);
      // socket.onopen = (event) => {
      //   socket.send('0');
      // };
      this.isPlaying = false;
      this.videos.currentTime = 0;
      sessionStorage.setItem('videos', JSON.stringify(this.videos));
    });
  }
  goToSubscription() {
    localStorage.setItem('subscribeUrl', window.location.pathname);
    this.router.navigate(['/subscription']);
  }
  ngOnDestroy() {
    this.player.dispose();
  }

}
