import { Component, OnInit, OnDestroy, AfterContentInit, HostListener, ViewChild } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import disableScroll from 'disable-scroll';
import { HeaderService } from 'src/app/core/services/header.service';
import { AuthService } from '../../core/auth/auth.service';
import { VideoShareService } from '../../core/services/video-share.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../core/services/user.service';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentInit {
  trending: any = [];
  new: any = [];
  recent: any = [];
  home_playlist: any[];
  webseries: any = [];
  banner: any = [];
  section: any = [];
  genre: any = [];
  home: any = [];
  routeSub: any;
  load: Boolean;
  defaultOwlCarousel: any = [];
  scrollEvent = true;
  viewPortEnable: Boolean = false;
  moved: any = 7;
  page = 1;
  tilepage = 1;
  shortspage = 1;
  newNextPage: Boolean = true;
  recentNextPage: Boolean = true;
  trendingNextPage: Boolean = true;
  previousPage: Boolean = false;
  sliderBox: any;
  nextMove: any;
  activeItem: any;
  ItemNumber = 7;
  trendingPage = 1;
  recentPage = 1;
  loadingSlider: any = [];
  sectionPage: any = [];
  sectionNextPage: any = [];
  categoriesVideos: any = [];
  search: any = false;
  homeSection: Boolean = true;
  site_link: any = [];
  getHomeCategory;
  getRecommendedVideos;
  currentDecryptUser: any;
  sliderMovedData: any;
  sliderRowItem: any;
  isPlaylistLoaded: Boolean = false;
  isTilePlaylistLoaded: Boolean = false;
  isShortsPlaylistLoaded: Boolean = false;
  playlists: any;
  tile_playlists: any;
  shorts: any;
  adminPlaylistNextPage: Boolean = true;
  adminPlaylistServiceData;
  adminTilePlaylistNextPage: Boolean = true;
  adminTilePlaylistServiceData;
  adminShortsPlaylistNextPage: Boolean = true;
  adminShortsPlaylistServiceData;
  playlistskeleton: Boolean = false;
  tile_playlistskeleton: Boolean = false;
  shorts_playlistskeleton: Boolean = false;
  currentLanguage: any = localStorage.getItem('currentLanguage');
  error: any = {};
  @HostListener('window:scroll', ['$event'])
  onscroll(e) {
    if (this.homeSection === false) {
      this.homeSection = true;
      if (!this.getHomeCategory) {
        this.getAdminPlaylists();
        this.getAdminTilePlaylists();
        this.getAdminShortsPlaylists();
      }
      if (this.isPlaylistLoaded || this.isTilePlaylistLoaded || this.isShortsPlaylistLoaded) {
        this.getHome();
      }
    }
  }
  constructor(
    private apiService: ApiService,
    private router: Router,
    private headerService: HeaderService,
    private authService: AuthService,
    private videoShareService: VideoShareService,
    private toasterService: ToastrService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {
    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params && params['token']) {
        this.activateUser(params['token']);
      }
    });
    this.currentDecryptUser = this.authService.decryption(localStorage.getItem('currentUser'));
    if (typeof this.currentDecryptUser !== 'undefined') {
      this.headerService.fetchBanner();
    }
    this.headerService.webseriesData$.subscribe(data => {
      this.webseries = data;
    });

    // this.getRecommendedVideosMethod();
    this.getHomeCategory = this.headerService.getHomeCategory();
    this.adminPlaylistServiceData = this.headerService.getAdminPlaylistsData();
    this.adminTilePlaylistServiceData = this.headerService.getAdminTilePlaylistsData();
    this.adminShortsPlaylistServiceData = this.headerService.getAdminShortsPlaylistsData();
    this.genre.data = [];
    this.new.data = [];
    this.recent.data = [];
    // this.webseries.data = [];
    this.categoriesVideos = [];
    this.sectionPage = [];
    this.sectionNextPage = [];
    this.trending.data = [];
    this.loadingSlider.data = [];

    for (let i = 0; i <= 4; i++) {
      this.loadingSlider.data.push({ 'thumbnail_image': environment.defaultImage });
    }
    this.getSiteLinks();
    this.headerService.bannerData$.subscribe(data => {
      this.banner = data;
    });
    this.headerService.newData$.subscribe(data => {
      this.new = data;
    });
    this.headerService.recentData$.subscribe(data => {
      this.recent = data;
    });
    this.headerService.home_playlist$.subscribe(data => {
      this.home_playlist = data;
      if (this.home_playlist) {
        this.home_playlist['data'].map(e => {
          if (e.poster_image === '') {
            e.poster_image = 'https://dyo1wwfvu9av7.cloudfront.net/jkp/common/default-poster.png';
          }
        });
      }
    });
    this.homeSection = false;
    if (this.getHomeCategory) {
      this.getHomeAfterData(this.getHomeCategory);
    }
    if (this.banner === null) {
      this.headerService.fetchBanner();
    }
    if (this.adminShortsPlaylistServiceData) {
      this.getAdminShortsPlaylists();
    }
    if (this.adminTilePlaylistServiceData) {
      this.getAdminTilePlaylists();
    }
    if (this.adminPlaylistServiceData) {
      this.getAdminPlaylists();
    }

  }
  ngAfterContentInit() {
    this.videoShareService.sliderMovedItems.subscribe(data => {
      this.sliderMovedData = data;
    });
    this.videoShareService.sliderRowItemsCount.subscribe(data => {
      this.sliderRowItem = data;
    });
  }

  getHome() {
    this.load = false;
    this.apiService.callGetAPI('v2/home_page')
      .subscribe(
        data => {
          this.headerService.setHomeCategory(data);
          this.getHomeAfterData(data);
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }
  getAdminPlaylists() {
    this.load = false;
    this.isPlaylistLoaded = true;
    this.apiService.callGetAPI('v2/home_page_dashboard?displaypage=homepage')
      .subscribe(
        data => {
          this.playlists = data['response'].playlists;
          //this.tile_playlists = data['response'].tile_playlists;
          this.headerService.setAdminPlaylistData(data);
          if (this.playlists.length === 0) {
            this.playlistskeleton = false;
          }
          /* if (this.tile_playlists.length === 0) {
            this.tile_playlistskeleton = false;
          } */
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }
  getAdminTilePlaylists() {
    this.load = false;
    this.isTilePlaylistLoaded = true;
    this.apiService.callGetAPI('v2/home_page_dashboard?displaypage=homepage')
      .subscribe(
        data => {
          //this.playlists = data['response'].playlists;
          this.tile_playlists = data['response'].tile_playlists;
          this.headerService.setAdminTilePlaylistData(data);
          if (this.tile_playlists.length === 0) {
            this.tile_playlistskeleton = false;
          }
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

  getAdminShortsPlaylists() {
    this.load = false;
    this.isShortsPlaylistLoaded = true;
    this.apiService.callGetAPI('v2/home_page_dashboard?displaypage=homepage')
      .subscribe(
        data => {
          //this.playlists = data['response'].playlists;
          this.shorts = data['response'].shorts;
          this.headerService.setAdminShortsPlaylistData(data);
          if (this.shorts.length === 0) {
            this.shorts_playlistskeleton = false;
          }
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

  getHomeAfterData(data) {
    if (data['statusCode'] === 200 && data['error'] === false) {
      let index = 0;
      this.categoriesVideos = [];
      for (const datas of data['response'].home_content) {
        this.categoriesVideos.push(datas);
        this.sectionPage.push(1);
        if (datas.total === this.categoriesVideos[index].data.length) {
          this.sectionNextPage[index] = false;
        } else {
          this.sectionNextPage[index] = true;

        }
        index++;
      }
      this.load = true;
    }
  }

  next(url) {
    switch (url) {
      case 'new': {
        this.nextObj();
        break;
      }
      case 'trending': {
        this.nextObjTrending();
        break;
      }
      case 'recent': {
        this.nextRecentObj();
        break;
      }
      case 'admin-playlist': {
        this.adminPlaylistNXtObject();
        break;
      }
      case 'admin-tile-playlist': {
        this.adminTilePlaylistNXtObject();
        break;
      }
      case 'admin-shorts-playlist': {
        this.adminShortsPlaylistNXtObject();
        break;
      }
    }
  }
  adminPlaylistNXtObject() {
    this.page++;
    if (!(this.playlists.data.length === this.playlists.total)) {
      this.apiService.callGetAPI('v2/home_page_dashboard?displaypage=homepage&page=' + this.page)
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].playlists.total === this.playlists.data.length) {
                this.adminPlaylistNextPage = false;
              }
              data['response'].playlists.data.forEach(value => {
                this.playlists.data.push(value);
              });
              /* data['response'].tile_playlists.data.forEach(value => {
                this.tile_playlists.data.push(value);
              }); */
            }
          },
          error => {
            this.apiService.failureResponse(error);
          });
    }
  }
  adminTilePlaylistNXtObject() {
    this.tilepage++;
    if (!(this.tile_playlists.data.length === this.tile_playlists.total)) {
      this.apiService.callGetAPI('v2/home_page_dashboard?displaypage=homepage&page=' + this.tilepage)
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].tile_playlists.total === this.tile_playlists.data.length) {
                this.adminTilePlaylistNextPage = false;
              }
              data['response'].tile_playlists.data.forEach(value => {
                this.tile_playlists.data.push(value);
              });
            }
          },
          error => {
            this.apiService.failureResponse(error);
          });
    }
  }

  adminShortsPlaylistNXtObject() {
    this.shortspage++;
    if (!(this.shorts.data.length === this.shorts.total)) {
      this.apiService.callGetAPI('v2/home_page_dashboard?displaypage=homepage&page=' + this.shortspage)
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].shorts.total === this.shorts.data.length) {
                this.adminShortsPlaylistNextPage = false;
              }
              data['response'].shorts.data.forEach(value => {
                this.shorts.data.push(value);
              });
            }
          },
          error => {
            this.apiService.failureResponse(error);
          });
    }
  }

  nextRecentObj() {
    this.recentPage++;
    if (this.recentNextPage) {
      this.apiService.callGetAPI('v2/home_more?type=recent&page=' + this.recentPage)
        .subscribe(
          data => {
            this.recent.category_name = data['response']['category_name'];
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.recent.data.length) {
                this.recentNextPage = false;
              }
              data['response'].data.forEach(value => {
                this.recent.data.push(value);
              });
            }
          },
          error => {
            this.apiService.failureResponse(error);
          });
    }
  }
  nextObj() {
    this.page++;
    if (!(this.new.data.length === this.new.total)) {
      this.apiService.callGetAPI('v2/home_more?type=new&page=' + this.page)
        .subscribe(
          data => {
            this.new.category_name = data['response']['category_name'];
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.new.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach(value => {
                this.new.data.push(value);
              });
            }
          },
          error => {
            this.apiService.failureResponse(error);
          });
    }
  }

  nextObjTrending() {
    this.trendingPage++;
    if (this.trendingNextPage) {
      this.apiService.callGetAPI('v2/home_more?type=trending&page=' + this.trendingPage)
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.trending.data.length) {
                this.trendingNextPage = false;
              }
              data['response'].data.forEach(value => {
                this.trending.data.push(value);
              });
            }
          },
          error => {
            this.apiService.failureResponse(error);
          });
    }
  }
  sectionNext(section, index) {
    if (this.sectionNextPage[index] === true) {
      this.sectionPage[index] += 1;
      this.apiService.callGetAPI('v2/home_more?type=' + section + '&page=' + this.sectionPage[index])
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach(value => {
                this.categoriesVideos[index].data.push(value);
              });
              if (data['response'].total === this.categoriesVideos[index].data.length) {
                this.sectionNextPage[index] = false;
              }
            }
          },
          error => {
            this.apiService.failureResponse(error);
          });
    }
  }

  getSiteLinks() {
    this.headerService.apiData$.subscribe(data => {
      if (data !== null) {
        this.site_link = data['response'].site_link;
      }
    });
    if (this.site_link === null) {
      setTimeout(() => {
        this.getSiteLinks();
      }, 1000);
    }
  }
  ngOnDestroy() {
    disableScroll.off();
    this.routeSub.unsubscribe();
  }

  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.href = this.site_link.android_app_link ? this.site_link.android_app_link : undefined;
      a.click();
    }
  }

  activateUser(key) {
    this.apiService.callPostAPI(
      'v2/auth/check_activation_code',
      {
        login_type: 'normal',
        acesstype: 'web',
        key: key,
      }, environment.userApiUrl)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.userService.displayPopup('sign-in');
            this.toasterService.success('', data['message']);
            disableScroll.off();
            this.router.navigate(['/home']);
          }
        },
        error => {
          if (error.error.statusCode === 422) {
            this.error = error.error.message;
            this.toasterService.error('', this.error);
            this.router.navigate(['/home']);
          } else {
            this.error = {};
            this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
            this.router.navigate(['/home']);
          }
        });
  }

  playlistClick(playlist, e) {
    this.router.navigate(['playlist/' + playlist.slug + '/' + playlist._id]);
  }
}
