import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../core/services/api.service";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit {
  banner: any = [];
  storySection: any = [];
  loader: boolean = false;
  story_page: boolean = true;
  loadingSlider: any = [];
  constructor(private apiService: ApiService,) { }

  ngOnInit() {
    this.loadingSlider.data = [];
    for (let i = 0; i <= 4; i++) {
      this.loadingSlider.data.push({ 'thumbnail_image': environment.defaultImage });
    }
    this.getBanner();
    this.getStorySections();
  }

  getBanner = () => {
    this.apiService
      .callGetAPI("v2/get_story_banner")
      .subscribe(
        (data) => {
          console.log(data);
          if(data['response'].banner){
            this.banner = data['response'].banner.data;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  getStorySections = () => {
    this.loader = true;
    this.apiService
      .callGetAPI("v2/story_section")
      .subscribe(
        (data) => {
          console.log(data);
          if(data['response']){
            this.storySection = Object.values(data['response']);
          }
          console.log('storySection',this.storySection)
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  checkData = data => {
    console.log(data);
    return 'test';
  }
  
}
