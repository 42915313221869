import { Component, OnInit, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerService } from '../../core/services/player.service';
import { PlatformLocation } from '@angular/common';
import videojs from 'src/assets/js/play-audio.es.js';
import { AudioService } from 'src/app/core/services/audio.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-watch-video',
  templateUrl: './watch-video.component.html',
  styleUrls: ['./watch-video.component.scss']
})
export class WatchVideoComponent implements OnInit, AfterViewInit, OnDestroy {
  player: any;
  params: any;
  videos: any;
  live: boolean;
  currentVideos: any;
  fullParams: any;
  x: any;
  loggedIn: any = {};
  liveChatStatus: Boolean = false;
  isMobile: Boolean = true;
  adminPlaylistSlugSubscribe: any;
  adminPlaylistSlug: string;
  notSubscribed: Boolean = false;
  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private playerService: PlayerService,
    private location: PlatformLocation,
    private router: Router,
    private audioService: AudioService,
    private el: ElementRef,
    private translate: TranslateService) {

    if (localStorage.getItem('currentLanguage')) {
      this.translate.use(localStorage.getItem('currentLanguage'));
    }
    /** Get admin playlist id query param */
    this.route.queryParams
      .filter(params => params.playlist_id)
      .subscribe(params => {
        this.adminPlaylistSlug = params.playlist_id;
      });
    this.route.params.subscribe(params => {
      this.fullParams = params;
      this.params = params['video_id'];
      this.getVideos();
    });

    // Getting dynamic window size for mobile
    this.getWindowSize();
    window.addEventListener('resize', this.getWindowSize);
  }

  ngOnInit() {
    const oldPlayer = document.getElementById('audio-player');
    videojs(oldPlayer).dispose();
    document.getElementsByTagName('body')[0].removeAttribute('style');
    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'light') {
        document.body.classList.add('main-light');
      }
      if (localStorage.getItem('theme') === 'dark') {
        document.body.classList.add('main-dark');
      }
    } else {
      document.body.classList.add('main-dark');
    }
  }
  ngAfterViewInit() {
    this.el.nativeElement.ownerDocument.body.style.backgroundColor = '#111418';
  }
  videoPlay(event) {
    this.player = event;
  }
  getVideos() {
    let url;
    if (this.adminPlaylistSlug) {
      url = 'v2/watchvideo/' + this.fullParams['video_id'] + '?playlist_id=' + this.adminPlaylistSlug;
    } else {
      url = 'v2/watchvideo/' + this.fullParams['video_id'];
    }

    this.apiService.callGetAPI(url)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.videos = data['response'];
            this.currentVideos = this.videos;
            this.playerService.videoData(this.videos.videos);
            this.playerService.videoPaymentData(this.videos.payment_info);
            this.loggedIn = localStorage.getItem('currentUser');
            // if (this.loggedIn) {
            //   this.apiService.callPostAPI('v2/videoanalytics', {'video_slug': this.videos.videos.slug}, environment.analyticsApiUrl)
            //   .subscribe();
            // }
          }
          if (this.videos.videos.scheduledStartTime !== '' && (this.videos.videos.scheduledStartTime > new Date().getTime())) {
            if (this.fullParams['playlist_id'] !== undefined) {
              this.router.navigate(['video/' + this.fullParams['video_id'] + '/' + this.fullParams['playlist_id'] + '/' + this.fullParams['name']]);
            } else {
              this.router.navigate(['video/' + this.fullParams['video_id']]);
            }
          }
          if (this.videos.videos.is_premium === 1) {
            if (this.videos.videos.is_subscribed === 0) {
              if (this.adminPlaylistSlug) {
                this.notSubscribed = true;
              } else if (this.fullParams['playlist_id'] !== undefined) {
                this.router.navigate(['video/' + this.fullParams['video_id'] + '/' + this.fullParams['playlist_id'] + '/' + this.fullParams['name']]);
              } else if (this.videos.payment_info.is_bought === 1) {
                return this.videos;
              } else {
                this.router.navigate(['video/' + this.fullParams['video_id']]);
              }
            }
          }
          if (!localStorage.getItem('currentUser')) {
            this.router.navigate(['video/' + this.fullParams['video_id']]);
          }
        },
        error => {
          if (this.fullParams['playlist_id'] !== undefined) {
            this.router.navigate(['video/' + this.fullParams['video_id'] + '/' + this.fullParams['playlist_id'] + '/' + this.fullParams['name']]);
          } else if (!this.adminPlaylistSlug) {
            this.router.navigate(['watch/' + this.fullParams['video_id']], { queryParams: { playlist_id: this.adminPlaylistSlug } });
          }
          if (error.status === 404) {
            this.router.navigate(['video-not-found']);
          } else {
            this.apiService.failureResponse(error);
          }
        });
  }
  ngOnDestroy() {

    this.el.nativeElement.ownerDocument.body.style.backgroundColor = '';
    this.playerService.setAdminPlaylistSlug(undefined);

  }
  /**
   * Get window size for configuration
   */
  getWindowSize() {
    if (window.innerWidth >= 1199) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
}
