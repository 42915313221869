import { Component, EventEmitter, Input, OnInit, Output, HostListener, ElementRef } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { VideoModel } from '../../../../modules/video-detail/video.model';
import { Router } from '@angular/router';
import { UserService } from '../../../../core/services/user.service';
import { PlayerService } from '../../../../core/services/player.service';
import { VideoShareService } from '../../../../core/services/video-share.service';
import { PlaylistAddService } from '../../../../core/services/playlist-add.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import disableScroll from 'disable-scroll';
import { trigger, transition, style, animate } from '@angular/animations';
import videojs from '../../../../../assets/js/play.es.js';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../../environments/environment';
import { StringRemoveWhitespace } from 'src/app/shared/stringremovewhitespace';
declare var $: any;
@Component({
  selector: 'app-trailer-card-shorts',
  templateUrl: './trailer-card-shorts.component.html',
  styleUrls: ['./trailer-card-shorts.component.scss'],
  animations: [
    trigger('videoCardAnimation', [
      transition(':enter', [
        style({ opacity: 0.2 }),
        animate('700ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ],
  providers: [StringRemoveWhitespace]
})
export class TrailerCardShortsComponent implements OnInit {
  @Input() video: any = {};
  @Input() history: any;
  @Input() live: boolean;
  @Input() type: string;
  @Input() category: string;
  @Input() playlist_id: any;
  @Input() playlist_name: any;
  @Input() currentlyPlaying: any;
  @Output() favouriteChange = new EventEmitter();
  @Output() historyChange = new EventEmitter();
  @Output() playlistChange = new EventEmitter();
  @Output() dragEvent = new EventEmitter<string>();
  @Output() videoCardClicked = new EventEmitter;
  @Input() blockType: any;
  loggedIn: any = {};
  tostar: any = {};
  loggedTrue: Boolean = false;
  showOption: any = false;
  showFlag: Boolean = false;
  shareDrop: any;
  videoDetails: any;
  playlist: any = [];
  loading: boolean;
  shareFlag: boolean;
  isShorts: boolean;
  flag: boolean;
  final: boolean;
  submitted: boolean;
  result: boolean;
  errors: any;
  success: any;
  utilityService: any;
  buttonText: string;
  playlistForm: any;
  playlistFlag: boolean;
  addZindex = false;
  slidItemClass = false;
  player: any;
  trailerHoverTimer;
  videoPlayHoverState = true;
  timeouts = [];
  cipherText: any;
  encrypted: any;
  decrypted: any;
  interVal: any;
  plainText: any;
  playerInterval = [];
  @HostListener('document:click', ['$event'])
  clickOut() {
    if (this.shareDrop !== undefined) {
      if (!this.eRef.nativeElement.contains(event.target)) {
        disableScroll.off();
        this.showOption = false;
        this.shareDrop = undefined;
        this.addZindex = false;
      }
    }
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private userService: UserService,
    private videoShareService: VideoShareService,
    private playerService: PlayerService,
    private eRef: ElementRef,
    private playlistAddService: PlaylistAddService,
    private meta: Meta,
    private pipe: StringRemoveWhitespace
  ) {
    // $(function () {
    //   $('.slider-img img').on('error', function () {
    //     $(this).attr({
    //       'src': 'https://dyo1wwfvu9av7.cloudfront.net/jkp/common/default-poster.png'
    //     });
    //   });
    // });
  }
  ngOnInit() {
    this.slidItemClass = this.type === 'live';
    this.videoDetails = this.video;
    this.playlistForm = new FormGroup({
      playlist_name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\s]*$')])
    });
    if (typeof this.video.is_shorts != 'undefined' && this.video.is_shorts == 1) {
      this.isShorts = true;
    } else {
      this.isShorts = false;
    }


    this.video.thumbnail_image = (this.video.thumbnail_image.length > 0) ? this.video.thumbnail_image : 'https://dyo1wwfvu9av7.cloudfront.net/jkp/common/default-poster.png';

    this.live = (this.live) ? true : false;
    if (localStorage.getItem('currentUser')) {
      this.loggedTrue = true;
    }
  }

  showOptions(event) {
    if (this.showOption === false) {
      disableScroll.on(undefined, { disableWheel: true, disableScroll: false, disableKeys: false });
    }
    this.addZindex = true;
    this.shareDrop = event.target;
    this.showOption = !this.showOption;
  }

  openShareVideo() {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    this.videoShareService.displayPopup(this.video);
  }
  openPlalistPopup() {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    this.playlistAddService.displayPopup(this.video);
  }
  favourite(video: VideoModel, e) {
    if (sessionStorage.getItem('tostar')) {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': JSON.parse(sessionStorage.getItem('tostar')).tostarTime
      };
    } else {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': 0
      };
    }
    sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
    e.stopPropagation();
    this.loggedIn = localStorage.getItem('currentUser');
    if (this.loggedIn) {
      const favourite = video.is_favourite;
      if (favourite) {
        this.apiService.callPostAPI('v2/favourite', { video_slug: video.slug })
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  this.apiService.successResponse(data, '');
                }
                this.video.is_favourite = favourite;
                this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              this.favouriteChange.emit(this.video);
            });
      } else {
        this.apiService.callDeleteAPI('v2/favourite?video_slug=' + video.slug)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  this.apiService.successResponse(data, '');
                }
                this.video.is_favourite = favourite;
                this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              this.favouriteChange.emit(this.video);
            });
      }

    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup('sign-in');
    }
  }

  removeHistory(video: VideoModel, e) {
    e.stopPropagation();
    this.apiService.callPostAPI('v2/clear_recent_view', { video_id: video.slug })
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.apiService.successResponse(data, '');
          }
        },
        error => {
          this.apiService.failureResponse(error, false);
        });
    this.historyChange.emit(this.video);
  }
  playerConfiguration(videoElem) {
    /*   videojs.Hls.xhr.beforeRequest = options => {
        options.headers = [];
        if (this.video.is_live === 0) {
          options.headers['Title'] = this.encrypt();
        }
      }; */
    this.player = videojs(videoElem, {
      autoplay: true,
      controls: false,
      preload: true,
      bigPlayButton: false,
      muted: true
    });
  }
  playTrailer(video, e) {
    // this.decrypt();
    if (this.videoPlayHoverState) {
      const blockType = this.pipe.transform(this.blockType);
      const videoslug = this.pipe.transform(video.slug);
      const videoSelector = 'trailer-video-' + blockType + '-' + videoslug;
      const getVideo = document.getElementById(videoSelector);
      if (getVideo != null) {
        if (video.trailer_hls_url !== null && video.trailer_hls_url !== '') {
          this.playerConfiguration(getVideo);
          this.trailerHoverTimer = setTimeout(function () {
            this.player.src({
              // tslint:disable-next-line:max-line-length
              // src: 'https://contusmmd-mmd-cust.s.llnwi.net/contus/limelight/videos/source/stream.ismd/manifest.m3u8?stream=330-video-359058-1351620000001-000001;330-video-359058-1351620000001-000010;330-video-359058-1351620000001-000020;330-video-359058-1351620000001-000050',
              src: video.trailer_hls_url,
              type: 'application/x-mpegURL'
            });
            getVideo.parentElement.style.display = 'block';
            this.player.load();
            this.player.on('play', function (e) {
              this.videoPlayHoverState = false;
            }.bind(this));
            this.player.on('ended', function (e) {
              // if (this.player.currentTime() >= 15) {
              this.videoPlayHoverState = true;
              this.player.pause();
              getVideo.parentElement.style.display = 'none';
              // }
            }.bind(this));
          }.bind(this), 1000, getVideo, video);
          this.timeouts.push(this.trailerHoverTimer);
        }
      }
    }
  }
  resetTrailer(video) {
    const blockType = this.pipe.transform(this.blockType);
    const videoslug = this.pipe.transform(video.slug);
    const videoSelector = 'trailer-video-' + blockType + '-' + videoslug;
    // const videoSelector = 'trailer-video-' + this.blockType.replace(" ","-") + '-' + video.slug.replace(" ","-");
    const getVideo = document.getElementById(videoSelector);
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
    for (let i = 0; i < this.playerInterval.length; i++) {

      clearInterval(this.playerInterval[i]);
    }
    this.playerInterval = [];
    this.plainText = null;
    this.timeouts = [];
    if (video.trailer_hls_url !== null && video.trailer_hls_url !== '') {
      this.playerConfiguration(getVideo);
      this.videoPlayHoverState = true;
      this.player.reset();
    }
  }
  decrypt() {
    if (typeof this.video.passphrase !== 'undefined') {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(this.video.passphrase)
      });
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        CryptoJS.enc.Hex.parse(environment.crypto_key),
        {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC
        }
      );
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      this.incrementInterVal();
    }
  }
  incrementInterVal() {
    this.interVal = setInterval(
      function () {
        this.plainText += 1;
      }.bind(this),
      1000
    );
    this.playerInterval.push(this.interVal);
  }

  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }
  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC
      }
    );
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }
  binaryToHex(num) {
    let str = '',
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
  }
  d2h(d) {
    return d.toString(16);
  }
  videoClick(video, imageClick) {
    if (imageClick === 'image') {
      disableScroll.off();
      this.showOption = false;
      this.shareDrop = undefined;
      this.addZindex = false;
      this.playerService.videoData(video);
      if (video.hasOwnProperty('is_live') && video.is_live === 0) {
        if (video.is_premium === 1) {
          if (video.is_subscribed === 1) {
            if (video.slug) {
              if (this.type === 'playlist') {
                this.router.navigate(['watch/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
              } else {
                this.router.navigate(['watch/' + video.slug]);
              }
            }

          } else {
            if (video.slug) {
              if (this.type === 'playlist') {
                this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
              } else {
                this.router.navigate(['video/' + video.slug]);
              }
            }
          }
        } else {
          if (video.slug) {
            if (this.type === 'playlist') {
              this.router.navigate(['watch/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
            } else {
              this.router.navigate(['watch/' + video.slug]);
            }
          }
        }
      } else {
        if (video.is_premium === 1) {
          if (this.type === 'playlist') {
            this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
          } else {
            this.router.navigate(['video/' + video.slug]);
          }
        } else {
          this.router.navigate(['watch/' + video.slug]);
        }


      }
    } else {
      disableScroll.off();
      this.showOption = false;
      this.shareDrop = undefined;
      this.addZindex = false;
      this.playerService.videoData(video);
      if (video.is_story) {
        this.router.navigate(['story-detail/' + video.slug]);
      } else {
        if (video.slug) {
          if (this.type === 'playlist') {
            this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
          } else {
            this.router.navigate(['video/' + video.slug]);
          }
        }
      }
    }
  }


  removeFromPlaylist(video: VideoModel, e) {
    e.stopPropagation();
    this.apiService.callDeleteAPI('v2/create_playlist_videos?playlist_id=' + this.playlist_id + '&video_id=' + this.video.slug)
      .subscribe(data => {
        this.apiService.successResponse(data);
      }, error => {
        this.apiService.failureResponse(error);
      });
    this.playlistChange.emit(video);
  }

  videoCardFullClick(video) {
    this.videoCardClicked.emit(video);
  }

}


