import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() banner;
  @Input() story;
  bannerData = [];
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.bannerData = this.banner;
  }
  // Banner click
  onBannerClick(video) {
    if (this.story) {
      this.router.navigate(['story-detail/' + video.videos.slug]);
    } else if (video.type == 'playlists') {
      this.router.navigate(['playlist/' + video.playlists.slug]);
    }
    else if (video.type == 'albums') {
      this.router.navigate(['albums/' + video.albums.slug]);
    }
    else if (video.type == 'webseries') {
      this.router.navigate(['webseries/' + video.webseries.slug]);
    }
    else if (video.type == 'radio') {
      this.router.navigate(['radio/']);
    }
    else {
      this.router.navigate(['video/' + video.videos.slug]);
    }
  }
}
