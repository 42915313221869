import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-luh-slider',
  templateUrl: './luh-slider.component.html',
  styleUrls: ['./luh-slider.component.scss']
})
export class LuhSliderComponent implements OnInit {

  @Input() listArray: [];
  @Input() whichSlide: string;
  @Input() isSquare: boolean;
  @Input() title: string;
  @Input() isSong: boolean;
  @Output() routeTo = new EventEmitter();
  @Output() playSong = new EventEmitter();
  @Output() favouriteSong = new EventEmitter();
  @Output() shareSong = new EventEmitter();
  @Output() shareArtist = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();
  @ViewChild('slider') sliderVar;
  public scrollAmount: number;
  public activeNextButton = true;
  public activeBothButton: boolean;
  public skeletonArray: number[];
  constructor() { }

  ngOnInit() {
    this.listArray = this.listArray || [];
    this.skeletonArray = [1, 2, 3, 4, 5, 6];
  }

  routeToParent(path: string) {
    console.log(path);
    this.routeTo.emit(path);
  }

  playSongParent(whichSlide: string, index: number, data) {
    this.playSong.emit({ whichSlide, index, data });
  }

  favouriteSongParent(whichSlide: string, index: number) {
    this.favouriteSong.emit({ whichSlide, index });
  }

  shareSongParent(data) {
    // data.thumbnail_image ='';
    // console.log(data.album_thumbnail);
    if (data.album_thumbnail !== '') {
      console.log('Audio');
      data.thumbnail_image = data.album_thumbnail;
      data.title = data.album_name;
    } else {
      // data.title  = data.album_name;
      // console.log('not audio');
    }
    if (data.album_name !== '') {
      data.title = data.album_name;
    }
    // data.thumbnail_image = data.album_thumbnail;
    this.shareSong.emit(data);
  }

  shareArtistParent(index: number) {
    console.log('shareSongParent');
    this.shareArtist.emit(index);
  }

  previousParent(slider) {
    if (!this.activeNextButton || this.activeBothButton) {
      this.previous.emit(slider);
      this.sideScroll(slider.nativeElement, 'left', 0, 1480, 10);
    }
  }

  nextParent(slider) {
    if (this.activeNextButton || this.activeBothButton) {
      this.next.emit(slider);
      this.sideScroll(slider.nativeElement, 'right', 0, 1480, 10);
    }
  }

  sideScroll(element, direction, speed, distance, step) {
    this.scrollAmount = 0;
    const slideTimer: any = setInterval(() => {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      this.scrollAmount += step;
      if (this.scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  onScroll(e) {
    if (e.srcElement.offsetWidth + e.srcElement.scrollLeft === e.srcElement.scrollWidth) {
      this.activeNextButton = false;
      this.activeBothButton = false;
    } else if (e.srcElement.scrollLeft === 0) {
      this.activeNextButton = true;
      this.activeBothButton = false;
    } else {
      this.activeBothButton = true;
    }
  }

  getContentTitle(data) {
    return data.hasOwnProperty('audio_title') ? data.audio_title : data.album_name;
  }

  getRouteUrl(data) {
    return data.hasOwnProperty('audio_title') ? `/audio/songs/${data.slug}` : `/audio/albums/${data.slug}`;
  }

}
