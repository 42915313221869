import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AudioService } from 'src/app/core/services/audio.service';

@Component({
  selector: 'app-audio-playlist-card',
  templateUrl: './audio-playlist-card.component.html',
  styleUrls: ['./audio-playlist-card.component.scss']
})
export class AudioPlaylistCardComponent implements OnInit {
  @Input() playlists: any = {};
  @Input() type: string;
  @Input() currentIndex: string
  slidItemClass = false;
  radioIndex = null;
  constructor(private router: Router,
    private audioService: AudioService) { }

  ngOnInit() {
    console.log('currentIndex',this.currentIndex)
    // this.audioService.songState$.subscribe(res => {
    //   this.updateRadioIndex();
    // });
    // this.updateRadioIndex();
    this.audioService.songState$.subscribe(res => {
      if (res === 'play') {
        this.updateRadioIndex();
      } else if (res === 'pause') {
        this.radioIndex = null;
      }
    })
  }
  playlistClick(playlistData) {
    this.router.navigate(['audio/playlist/' + playlistData.playlist_slug]);
  }

  updateRadioIndex() {
    const id = this.audioService.getCurrentPlayingRadioId();
    console.log('id',id)
    if(this.currentIndex === id){
      console.log('this.radioIndex',this.radioIndex)
      this.radioIndex = id;
    } else {
      this.radioIndex = null;
    }
    console.log('this.radioIndex',this.radioIndex)
  }
}
