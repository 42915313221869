import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-see-all-story',
  templateUrl: './see-all-story.component.html',
  styleUrls: ['./see-all-story.component.scss']
})
export class SeeAllStoryComponent implements OnInit {
  type: any = '';
  story: any = [];
  page:any = 0;
  loading: boolean = true;
  total:any = 0;

  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if ($(window).scrollTop() + $(window).height() > $('footer').offset().top && !this.loading && this.total >= this.page) {
      console.log('inn');
      this.loading = true;
      this.getStoryByType(this.type);
    }
  }

  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router) {
      this.route.params.subscribe(params => {
        this.type = params['type'];
      });
     }

  ngOnInit() {
    this.getStoryByType(this.type);
  }

  getStoryByType = type => {
    this.page = this.page + 1;
    this.apiService.callGetAPI(
      'v2/story_section/?type=' + type + '&page=' + this.page)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            if(this.page == 1){
              this.story = data['response'].story;
            } else {
              this.story.data = [...this.story.data,...data['response'].story.data];
            }
            this.loading = false;
            this.total = data['response'].story.last_page;
          }
        },
        error => {
          this.apiService.failureResponse(error);
        }
      );
  }

  videoClick = (video, imageClick) => {
      if (video.slug) {
          this.router.navigate(['/story-detail/' + video.slug]);
      }
  }

}