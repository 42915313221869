import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { NgForm, EmailValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import disableScroll from 'disable-scroll';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { HeaderService } from 'src/app/core/services/header.service';
import { async } from '@angular/core/testing';

declare var $: any;
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  firstName: string;
  lastName: string;
  email: EmailValidator;
  phone: string;
  city: string;
  // tslint:disable-next-line: quotemark
  state = "";
  password: string;
  confirmPassword: string;
  mobile: number;
  date: Date;
  touched = false;
  taken: boolean;
  error: any = {};
  title = 'SIGN UP';
  show: Boolean = false;
  showConf: Boolean = false;
  submitted: any = false;
  submit_btn: any = false;
  dob: any;
  signUp = true;
  signUpContinue = false;
  signUpData: any;
  requestData: any;
  otpValue: any;
  terms: any = true;

  // date = new Date();
  options: DatepickerOptions = {
    minYear: 1980,
    displayFormat: 'YYYY',
    barTitleFormat: 'YYYY',
    // placeholder: 'Date of Birth',
    useEmptyBarTitle: false,
    maxDate: new Date(Date.now()),
  };
  languages: any;
  countries: any;
  philosophies: any;
  selectedCountry: any = '';
  selectedLanguage: any = 1;
  selectedPhilosophy: any = '';
  selectedPreacher: any = '';
  code: any;
  codes: any;
  languageCode: any;
  preachers: any;
  // @Output() event = new EventEmitter<string>();
  page: any = 1;
  categoriesVideos: any[];
  sectionPage: any;
  sectionNextPage: any;
  load: boolean;
  getHomeCategory: any;
  banner: any;
  datepick = false;
  country_code: any;
  iso: any;
  accept = false;
  inttelInputInstance;
  // tslint:disable-next-line: quotemark
  country_id: any = "";
  initialCountry = 'in';
  inttelInputOptions: object = {
    initialCountry: this.initialCountry
  };
  state_json = [];

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToastrService,
    private translate: TranslateService,
    private headerService: HeaderService,

  ) {
  }

  ngOnInit() {
    this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
      this.title = res;
    });
    this.apiService.callGetAPI('v2/auth/get_signup_info', environment.userApiUrl).subscribe(data => {
      this.languages = data['response']['languages'];
      this.state_json = data['response']['state'];
      this.countries = data['response']['countries'];
      this.philosophies = data['response']['referrals'];
      this.languageCode = data['response']['languages'];
      this.preachers = data['response']['preachers'];
    });
    if (localStorage.getItem('currentLanguage') == 'hi') {
      this.selectedLanguage = 2;
    }
  }
  resendOtp() {
    this.apiService.callPostAPI(
      'v2/auth/resend_otp',
      this.signUpData,
      environment.userApiUrl)
      .subscribe(
        data => {
          this.submitted = false;
          if (data['statusCode'] === 200 && data['error'] === false) {
          }
        },
        error => {
          this.submitted = false;
          this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
            this.title = res;
          });
          this.error = {};
          this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
        });
  }
  checkedOf(event) {
    if (event.target.checked === true) {
      this.accept = this.submit_btn = this.terms = true;
    } else {
      this.accept = this.submit_btn = this.terms = false;
    }
  }
  telInputObject(obj) {
    this.inttelInputInstance = obj;
    this.countries = this.inttelInputInstance.intlTelInput.getCountryData();
  }
  getStateList(country_id) {
    this.apiService.callGetAPI('v2/geo-regions/' + country_id, environment.userApiUrl)
      .subscribe(data => {
        this.state_json = data['info']['regions'];
        this.state = '';
      });
  }

  onSignUp(form: NgForm) {
    this.touched = true;
    if (form.valid && form.value.password === form.value.confirmPassword) {
      this.submitted = true;
      this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
        this.title = res + '...';
      });
      this.signUpData.otp = form.value.otp;
      this.signUpData.is_terms_verified = this.accept;
      this.apiService.callPostAPI(
        'v2/auth/register',
        this.signUpData,
        environment.userApiUrl)
        .subscribe(
          data => {
            this.submitted = false;
            this.submit_btn = false;
            if (data['statusCode'] === 200 && data['error'] === false) {
              const userData = this.authService.encryption(data);
              this.userService.userData(userData);
              localStorage.setItem('currentUser', userData);
              localStorage.removeItem('audioPage');
              setTimeout(() => {
                this.toasterService.success('', data['message']);
              }, 1000);
              this.userService.displayPopup('colse');
              disableScroll.off();
              this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
                this.title = res;
              });
              // next page Observable
              /*this.userService.nextPage.subscribe(
                nextUrl => {
                  if (!localStorage.getItem('myUrl')) {
                    if (nextUrl.indexOf('video') !== -1) {
                      window.location.reload();
                    } else {
                      this.router.navigate([nextUrl]);
                    }
                  } else if (localStorage.getItem('myUrl')) {
                    if (data['response'].is_subscribed === 0) {
                      localStorage.setItem('subscribeUrl', window.location.pathname);
                      this.router.navigate(['subscription']);
                      localStorage.removeItem('myUrl');
                    } else if (data['response'].is_subscribed === 1) {
                      this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                      localStorage.removeItem('myUrl');
                    }
                  }
                });
                */
              if (this.signUpData.preferred_language) {
                this.codes = this.signUpData.preferred_language - 1;
                this.code = this.languageCode[this.codes].code;
                localStorage.setItem('currentLanguage', this.code);
              }
              $('.lang-dropdown ul li')[data['response'].preferred_language - 1].click();
              $('#lang-toggle').click();
              this.router.navigate(['/loading']);
            }

          },
          error => {
            this.submitted = false;
            this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
              this.title = res;
            });
            if (error.error.code === 422) {
              if (typeof error.error.message === 'string' || error.error.message instanceof String) {
                this.toasterService.error('', error.error.message);
              } else {
                this.submitted = false;
                this.error = error.error.error;
              }
            } else {
              this.error = {};
              this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
            }
          });
    }
  }

  onCountryChange(data) {
    this.country_code = data.dialCode;
  }

  onSignUpContinue(form: NgForm) {
    // console.log('dsfdf');
    // return false;
    this.touched = true;
    if (form.valid) {
      this.submitted = true;
      if (this.phone !== undefined && form.value.phone !== '' && form.value.email !== '') {
        this.translate.get('SIGN_UP.CONTINUE').subscribe(res => {
          this.title = res + '...';
        });
        this.signUpData = ({
          login_type: 'normal',
          acesstype: 'web',
          password: form.value.password,
          password_confirmation: form.value.password,
          name: form.value.firstName,
          country_code: this.country_code ? this.country_code : 91,
          phone: form.value.phone,
          email: form.value.email,
          country_id: form.value.selectedCountry,
          state: form.value.state,
          city: form.value.city,
          preferred_language: form.value.selectedLanguage,
          philosophy: (form.value.selectedPhilosophy) ? form.value.selectedPhilosophy : 0,
          preacher: (form.value.selectedPreacher) ? form.value.selectedPreacher : 0,
          otp: ''
        });
        this.requestData = {
          login_type: 'normal',
          acesstype: 'web',
          password: form.value.password,
          password_confirmation: form.value.password,
          name: form.value.firstName,
          country_id: form.value.selectedCountry,
          state: form.value.state,
          city: form.value.city,
          country_code: this.country_code ? this.country_code : 91,
          phone: form.value.phone,
          email: form.value.email,
          preferred_language: form.value.selectedLanguage,
          philosophy: (form.value.selectedPhilosophy) ? form.value.selectedPhilosophy : 0,
          preacher: (form.value.selectedPreacher) ? form.value.selectedPreacher : 0,
        };
      } else if (this.phone !== undefined && form.value.phone !== '') {
        this.translate.get('SIGN_UP.CONTINUE').subscribe(res => {
          this.title = res + '...';
        });
        this.signUpData = ({
          login_type: 'normal',
          acesstype: 'web',
          password: form.value.password,
          password_confirmation: form.value.password,
          name: form.value.firstName,
          phone: form.value.phone,
          // age: form.value.dob,
          country_code: this.country_code ? this.country_code : 91,
          country_id: form.value.selectedCountry,
          state: form.value.state,
          city: form.value.city,
          preferred_language: form.value.selectedLanguage,
          philosophy: (form.value.selectedPhilosophy) ? form.value.selectedPhilosophy : 0,
          preacher: (form.value.selectedPreacher) ? form.value.selectedPreacher : 0,
          otp: ''
        });
        this.requestData = {
          login_type: 'normal',
          acesstype: 'web',
          password: form.value.password,
          password_confirmation: form.value.password,
          name: form.value.firstName,
          // age: form.value.dob,
          country_code: this.country_code ? this.country_code : 91,
          country_id: form.value.selectedCountry,
          state: form.value.state,
          city: form.value.city,
          phone: form.value.phone,
          preferred_language: form.value.selectedLanguage,
          philosophy: (form.value.selectedPhilosophy) ? form.value.selectedPhilosophy : 0,
          preacher: (form.value.selectedPreacher) ? form.value.selectedPreacher : 0,
        };
      } else if (form.value.email !== '') {
        this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
          this.title = res + '...';
        });
        this.requestData = {
          login_type: 'normal',
          acesstype: 'web',
          email: form.value.email,
          password: form.value.password,
          password_confirmation: form.value.password,
          name: form.value.firstName,
          // age: form.value.dob,
          country_code: this.country_code ? this.country_code : 91,
          country_id: form.value.selectedCountry,
          state: form.value.state,
          city: form.value.city,
          preferred_language: form.value.selectedLanguage,
          philosophy: (form.value.selectedPhilosophy) ? form.value.selectedPhilosophy : 0,
          preacher: (form.value.selectedPreacher) ? form.value.selectedPreacher : 0,
        };
      } else {
        this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
          this.title = res + '...';
        });
        this.requestData = {
          login_type: 'normal',
          acesstype: 'web',
          password: form.value.password,
          password_confirmation: form.value.password,
          name: form.value.firstName,
          // age: form.value.dob,
          country_code: this.country_code ? this.country_code : 91,
          country_id: form.value.selectedCountry,
          state: form.value.state,
          city: form.value.city,
          preferred_language: form.value.selectedLanguage,
          philosophy: (form.value.selectedPhilosophy) ? form.value.selectedPhilosophy : 0,
          preacher: (form.value.selectedPreacher) ? form.value.selectedPreacher : 0,
        };
      }
      this.requestData.is_terms_verified = this.accept;
      this.apiService.callPostAPI(
        'v2/auth/otp/register',
        this.requestData
        , environment.userApiUrl)
        .subscribe(
          data => {
            this.submitted = false;
            this.submit_btn = false;
            if (data['statusCode'] === 200 && data['error'] === false) {
              localStorage.removeItem('audioPage');
              if (this.phone !== undefined && this.phone !== '') {
                if (data['response'].otp !== null) {
                  this.onShowHide('sign-up');
                  this.otpValue = data['response'].otp;
                } else {
                  setTimeout(() => {
                    this.toasterService.success('', data['message']);
                  }, 1000);
                  this.userService.displayPopup('colse');
                  this.userService.nextPage.subscribe(
                    nextUrl => {
                      if (!localStorage.getItem('myUrl')) {
                        if (nextUrl.indexOf('video') !== -1) {
                          this.router.navigate(['/loading']);
                        } else {
                          this.router.navigate([nextUrl]);
                        }
                      } else if (localStorage.getItem('myUrl')) {
                        if (data['response'].is_subscribed === 0) {
                          localStorage.setItem('subscribeUrl', window.location.pathname);
                          this.router.navigate(['subscription']);
                          localStorage.removeItem('myUrl');
                        } else if (data['response'].is_subscribed === 1) {
                          this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                          localStorage.removeItem('myUrl');
                        }
                      }
                    });
                }
              } else {
                const userData = this.authService.encryption(data);
                this.userService.userData(userData);
                localStorage.setItem('currentUser', userData);
                this.userService.displayPopup('colse');
                disableScroll.off();
                this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
                  this.title = res;
                });
              }
            }
          },
          error => {
            this.submitted = false;
            this.submit_btn = true;
            this.accept = true;
            this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
              this.title = res;
            });
            if (error.error.statusCode === 422) {
              if (typeof error.error.message === 'string' || error.error.message instanceof String) {
                this.toasterService.error('', error.error.message);
              } else {
                this.error = error.error.message;
              }
            } else {
              this.error = {};
              this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
            }
          });
    }

  }

  // Popup show or hide
  onShowHide(value: string) {
    if (value === 'sign-up') {
      this.signUpContinue = true;
      this.signUp = false;
    }
  }
  onClose() {
    disableScroll.off();
    if (this.route.snapshot['_routerState'].url === '/sign-up') {
      this.router.navigate(['home']);
    }
    this.userService.displayPopup('colse');
  }

  onClick(value: string) {
    if (value === 'sign-in') {
      this.userService.displayPopup('sign-in');
    }
  }
  onChange(e) {
    if (this.error && this.error[e.target.getAttribute('name')]) {
      this.error[e.target.getAttribute('name')] = '';
    }

    // if(e.target.getAttribute('name') === 'email'){
    //   let element = document.getElementsByClassName('email');
    //   element[0].classList.add('focus_enable');
    // }
    // if(e.target.getAttribute('name') === 'phone'){
    //   let element = document.getElementsByClassName('phone');
    //   element[0].classList.add('focus_enable');
    // }
    // if(e.target.getAttribute('name') === 'dob'){
    //   let element = document.getElementsByClassName('dob');
    //   element[0].classList.add('focus_enable');
    // }
    // if (e.target.name === '') {
    //   this.error = [];
    //   this.datepick = true;
    //   this.error[e.target.name] = '';
    // }

    if (this.phone !== undefined && this.phone !== '') {

      this.translate.get('SIGN_UP.CONTINUE').subscribe(res => {
        this.title = res;
      });
    }
    if (this.dob !== undefined && this.dob !== '') {
      if (this.dob.getTime() > (new Date().getTime() - 315585697857)) {
        this.datepick = true;
      } else {
        this.datepick = false;
      }
    }
  }
  toggleShowPassword() {
    this.show = !this.show;
  }

  toggleShowConfirmPassword() {
    this.showConf = !this.showConf;
  }

}
