import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/services/header.service';
import { UserService } from 'src/app/core/services/user.service';
import { VideoShareService } from 'src/app/core/services/video-share.service';
import { PlaylistAddService } from 'src/app/core/services/playlist-add.service';
import { AudioService } from 'src/app/core/services/audio.service';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit {

  public newReleasesArray: [];
  public trendingNowArray: [];
  public weeklyTop15Array: [];
  public topArtistsArray: [];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private userService: UserService,
    private videoShareService: VideoShareService,
    private playlistAddService: PlaylistAddService,
    private audioService: AudioService,
    private headerService: HeaderService
  ) {

  }
  ngOnInit() {
    this.getHomeData();
  }

  getHomeData() {
    this.apiService.callGetAPI('v2/homepage').subscribe(data => {
      if (data !== null && data !== undefined) {
        if (!data['error']) {
          this.newReleasesArray = data['response']['home_content'][0].data;
          this.getMoreHomeData();
        }
      }
    });
  }

  getMoreHomeData() {
    this.apiService.callGetAPI('v2/homepage_more').subscribe(data => {
      if (data !== null && data !== undefined) {
        if (!data['error']) {
          data['response']['home_content'].forEach(element => {
            if (element.type === 'trending_now') {
              this.trendingNowArray = element.data;
            } else if (element.type === 'weekly_top') {
              this.weeklyTop15Array = element.data;
            } else if (element.type === 'featured_artists') {
              this.topArtistsArray = element.data;
            }
          });
        }
      }
    });
  }


  routeTo(path) {
    this.router.navigate([path]);
  }


  playSong(value, i, data?) {
    switch (value) {
      case 'trending': {
        this.callAudioServiceSetData(this.trendingNowArray, i);
        break;
      }
      case 'weekly': {
        this.callAudioServiceSetData(this.weeklyTop15Array, i);
        break;
      }
      case 'new': {
        this.apiService.callGetAPI('v2/album_tracks?slug=' + data.slug).subscribe(res => {
          this.routeTo('/audio/albums/' + data.slug);
          this.callAudioServiceSetData(res['response'].audio_info.data, 0);
        });
      }
    }
  }

  callAudioServiceSetData(array, i) {
    this.audioService.setData({
      'songList': array,
      'selectedindex': i
    });
    this.audioService.newStateSong('play');
  }

  shareSong(data) {
    this.videoShareService.displayPopup(data);
    this.videoShareService.displayPopupBlur(2);
  }

  favouriteSong(value, index) {
    const loggedIn = localStorage.getItem('currentUser');
    if (loggedIn) {
      switch (value) {
        case 'trending': {
          this.favouriteSongApi(this.trendingNowArray, index);
          break;
        }
        case 'weekly': {
          this.favouriteSongApi(this.weeklyTop15Array, index);
          break;
        }
        case 'new': {
          this.favouriteSongApi(this.newReleasesArray, index);
          break;
        }
      }
    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup(1);
      this.userService.displayPopupBlur(9);
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup('sign-in');
     }
  }

  favouriteSongApi(array, index) {
    if (array[index]['is_favourite'] === 0 || array[index].is_favourite === undefined) {
      this.apiService.callPostAPI(`v2/${array[index].hasOwnProperty('audio_url') ? 'audio' : 'album'}-favourite?slug=${array[index].slug}`, {}).subscribe(res => {
        this.apiService.successResponse(res);
        array[index].is_favourite = 1;
      },
        error => {
          this.apiService.failureResponse(error);
        });
    } else {
      this.apiService.callPutAPI(`v2/${array[index].hasOwnProperty('audio_url') ? 'audio' : 'album'}-favourite?slug=${array[index].slug}`, {}).subscribe(res => {
        this.apiService.successResponse(res);
        array[index].is_favourite = 0;
      },
        error => {
          this.apiService.failureResponse(error);
        });
    }
  }

  shareArtist(index) {
    this.videoShareService.displayPopup(this.topArtistsArray[index]);
    this.videoShareService.displayPopupBlur(2);
  }

}
