import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';
import { UtilityService } from '../../core/services/utility.service';
import disableScroll from 'disable-scroll';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  user: any = {};
  submitted = false;
  usernameMaxLength: any = 100;
  pwdMinLength: any = 6;
  pwdMaxlength: any = 60;
  LoggedIn = localStorage.getItem('currentUser');
  error: any = {};
  buttonText: any;
  commonError: string;
  show: Boolean = false;
  touched = false;
  @Output() languageId: any;
  @Output() phone: any;
  @Output() event = new EventEmitter;
  @Output() updateSubscription = new EventEmitter;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toasterService: ToastrService,
    private userService: UserService,
    private utilityService: UtilityService,
    private translate: TranslateService
  ) {
    this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
      this.buttonText = res;
    });
   }

  ngOnInit() {
    if (this.LoggedIn) {
      this.router.navigate(['home']);
    }
  }
  login() {
    this.touched = true;
    this.submitted = true;
    this.translate.get('SIGN_IN.SIGNINNG').subscribe(res => {
      this.buttonText = res;
    });
    this.user.provider = 'NORMAL';
    this.authService.login(this.user)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.updateSubscription.emit(data['response'].is_subscribed);
            disableScroll.off();
            this.userService.displayPopup('close');
            localStorage.removeItem('audioPage');
            if (data['response'].name) {
              this.languageId = data['response'].preferred_language - 1;
              this.event.emit(this.languageId);
              // this.phone = data['response'].phone;
              // this.event.emit(this.phone);
              const userData = this.authService.encryption(data);
              localStorage.setItem('currentUser', userData);
            }
            this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
              this.buttonText = res;
            });
            $('.lang-dropdown ul li')[data['response'].preferred_language - 1].click();
            $('#lang-toggle').click();
            this.router.navigate(['/loading']);
            // next page Observable
            /*this.userService.nextPage.subscribe(
              nextUrl => {
                if (!localStorage.getItem('myUrl')) {
                  if (nextUrl.indexOf('video') !== -1) {
                    window.location.reload();
                  } else {
                    this.router.navigate([nextUrl]);
                  }
                } else if (localStorage.getItem('myUrl')) {
                  if (data['response'].is_subscribed === 0) {
                    localStorage.setItem('subscribeUrl', window.location.pathname);
                    this.router.navigate(['subscription']);
                    localStorage.removeItem('myUrl');
                  } else if (data['response'].is_subscribed === 1) {
                    this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                    localStorage.removeItem('myUrl');
                  }
                }
              });*/
          }
        },
        error => {
          this.submitted = false;
          this.translate.get('SIGN_IN.SIGN_IN').subscribe(res => {
            this.buttonText = res;
          });
          if (error.error.statusCode === 422) {
            if (typeof error.error.message === 'string' || error.error.message instanceof String) {
              this.commonError = (error.error.message) ? error.error.message : error.message;
              this.utilityService.VplyToaster();
            } else {
              this.error = error.error.message;
            }
          } else {
            this.error = {};
            this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
          }
        }
      );
  }
  
  updateSubscriptionData(data){
    this.updateSubscription.emit(data);
  }
  onChange(e) {
    this.error[e.target.name] = '';
  }

  onClose() {
    disableScroll.off();
    if (this.route.snapshot['_routerState'].url === '/sign-in') {
      this.router.navigate(['home']);
    }
    this.userService.displayPopup('close');
  }

  onClick(value: string) {
    if (value === 'forget') {
      this.userService.displayPopup('forget');
    } else {
      this.userService.displayPopup('sign-up');
    }
  }
  VplyToasterHide() {
    this.utilityService.VplyToasterHide();
  }
  toggleShowPassword() {
    this.show = !this.show;
  }
}
