import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from '../../../../environments/environment';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import disableScroll from 'disable-scroll';

@Component({
  selector: 'app-webseries-detail',
  templateUrl: './webseries-detail.component.html',
  styleUrls: ['./webseries-detail.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms', style({ opacity: 0 }))
      ])
    ]
    ),
    trigger('enterMainAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ])
    ])
  ],
})

export class WebseriesDetailComponent implements OnDestroy {
  notFound: Boolean = false;
  noVideo: any;
  player: any;
  params: any;
  videos: any;
  page = [];
  live: boolean;
  loader: Boolean = true;
  currentVideos: any;
  fullParams: any;
  webseriesList: any = [];
  loadingSlider: any = [];
  x: any;
  liveChatStatus: Boolean = false;
  isMobile: Boolean = true;
  routeSub: any;
  relatedWebSeriesPage = 1;
  subWebseriesSection: Boolean = true;
  loadWebseries: Boolean = false;
  genre_series: any = [];
  genre_series_section: any = [];
  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if (this.subWebseriesSection === false) {
      this.subWebseriesSection = true;
        this.getSubWebSeriesList();
    }
  }
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private el: ElementRef) {
    this.loadingSlider.data = [];
    this.notFound = false;
    let screenSize;
    if (window.screen.width <= 360) {
      screenSize = 2;
    } else if (window.screen.width > 360 && window.screen.width <= 1299) {
      screenSize = 5;
    } else {
      screenSize = 6;
    }
    for (let i = 0; i <= screenSize; i++) {
      this.loadingSlider.data.push({ 'thumbnail_image': environment.defaultImage });
    }
    // Router Reuse strategy to navigate the url
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };
    this.routeSub = this.router.events.subscribe((params) => {
      if (params instanceof NavigationEnd) {
        this.loadWebseries = false;
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        this.loader = true;
        this.genre_series = [];
        this.genre_series_section = [];
        this.webseriesList = [];
        this.fullParams = this.route.snapshot.params.slug;
        if (this.loadWebseries === false) {
          setTimeout(() => {
            this.getWebSeriesList();
          }, 1000);
        }
      }
    });
  }
  /**
   * Get webseries list based on the parent series
   */
  getWebSeriesList() {
    this.loadWebseries = true;
    this.subWebseriesSection = false;
    this.apiService.callGetAPI('v2/childWebseries/' + this.fullParams).subscribe(response => {
      if (response['response']) {
        this.loader = false;
        this.relatedWebSeriesPage = 1;
        this.webseriesList = response['response'].main_webseries[0];
        this.genre_series_section = response['response'].genre_webseries;
        for (const genre of this.genre_series_section) {
          this.page.push({ page: 1, id: genre.slug, type: genre.type });
        }
        if (!(this.webseriesList && this.webseriesList.data.length > 0)) {
          this.notFound = true;
          this.noVideo = 'Sorry! No Series Found';
        } else {
          this.notFound = false;
        }
      }
    });
  }

  /**
   * Get webseries list based on the genre
   */
  getSubWebSeriesList() {
    this.apiService.callGetAPI('v2/childWebseries/' + this.fullParams + '?section=2').subscribe(data => {
      if (data['response']) {
        this.loader = false;
        const response = data['response'];
        this.genre_series = response.genre_webseries;
        for (const genre of this.genre_series) {
          this.page.push({ page: 1, id: genre.slug, type: genre.type });
        }
      }
    });
  }
  /**
   * Get more web series based on the parent category
   *
   */
  getMoreWebseries() {
    this.relatedWebSeriesPage += 1;
    let url;
    const webseries = true;
    url = 'v2/childWebseries/' + this.fullParams;
    url += '?page=' + this.relatedWebSeriesPage;
    if (this.webseriesList.next_page_url !== null) {
      this.apiService.callGetAPI(url).subscribe(data => {
        if (this.relatedWebSeriesPage === 1) {
          this.webseriesList.data = data['response'].main_webseries[0].data;
          this.webseriesList.total = data['response'].main_webseries[0].total;
          this.webseriesList.next_page_url = data['response'].main_webseries[0].next_page_url;
        } else {
          this.webseriesList.next_page_url = data['response'].main_webseries[0].next_page_url;
          this.webseriesList.data = this.webseriesList.data.concat(data['response'].main_webseries[0].data);
        }
        this.loader = false;
      },
        error => {
          this.apiService.failureResponse(error);
        });
    }
  }
  /**
   * Get more genre based web series (pagination is handled here)
   */
  getMoreSubwebseries(index, category_type, category_id, genres_id) {
    let url;
    const webseries = true;
    url = 'v2/more_child_webseries?';
    if (category_type === 'genre') {
      let i = 0;
      for (const pages of this.page) {
        if (pages.id === genres_id) {
          this.page[i].page++;
          break;
        }
        i++;
      }
      if (this.genre_series[index].series_list.next_page_url !== null) {
        this.apiService.callGetAPI(url + '&type=' + 'genre' + '&category=' + category_id + '&genre=' + genres_id + '&page=' + this.page[i].page).subscribe(data => {
          if (data['response'].more_webseries.series_list) {
            if (data['response'].more_webseries.series_list.next_page_url === null) {
              this.genre_series[index].series_list.next_page_url = null;
            }

            for (const datas of data['response'].more_webseries.series_list.data) {
              this.genre_series[index].series_list.data.push(datas);
            }
          } else {
            this.genre_series[index].series_list.next_page_url = null;
          }
        },
          error => {
            this.apiService.failureResponse(error);
          });
      }
    }
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
