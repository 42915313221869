import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoShareService {

  constructor() { }
  private popup = new BehaviorSubject<any>('close');
  display = this.popup.asObservable();
  private getSliderMovedItems = new BehaviorSubject<any>('');
  sliderMovedItems = this.getSliderMovedItems.asObservable();
  private getSliderRowItemsCount = new BehaviorSubject<any>('');
  sliderRowItemsCount = this.getSliderRowItemsCount.asObservable();
  private playlistSharePopup = new BehaviorSubject<any>('close');
  obtainAdminPlaylistShareData = this.playlistSharePopup.asObservable();
  private webSeriesSharePopup = new BehaviorSubject<any>('close');
  obtainWebSeriesShareData = this.webSeriesSharePopup.asObservable();
  private restrictVideoPopup = new BehaviorSubject<any>('close');
  obtainRestrictVideoPlay = this.restrictVideoPopup.asObservable();
  private restrictVideoPopupGeoIP = new BehaviorSubject<any>('close');
  obtainRestrictVideoPlayGeoIP = this.restrictVideoPopupGeoIP.asObservable();
  private restrictSubscriptionPopup = new BehaviorSubject<any>('close');
  obtainRestrictSubscription = this.restrictSubscriptionPopup.asObservable();
  // Observable share popup blur details
  private popupBlur = new BehaviorSubject<any>(1);
  displayPopUpBlur = this.popupBlur.asObservable();
  // video share popup
  displayPopup(data: any) {
    this.popup.next(data);
  }
  // video share popupBlur
  displayPopupBlur(data: any) {
    this.popupBlur.next(data);
  }
  setSliderMovedItems(data: any) {
    this.getSliderMovedItems.next(data);
  }
  setSliderRowItems(data: any) {
    this.getSliderRowItemsCount.next(data);
  }

  // Admin Playlists share popup handling
  setAdminPlaylistShareData(data: any) {
    this.playlistSharePopup.next(data);
  }

  // Web Series share popup handling
  setWebSeriesShareData(data: any) {
    this.webSeriesSharePopup.next(data);
  }

  setRestrictVideoPlay(data: any) {
    this.restrictVideoPopup.next(data);
  }
  setRestrictVideoPlayForGeoIP(data: any) {
    this.restrictVideoPopupGeoIP.next(data);
  }
  setRestrictSubscriptionPopup(data: any) {
    this.restrictSubscriptionPopup.next(data);
  }


}
