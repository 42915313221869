import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AudioService } from 'src/app/core/services/audio.service';
import { UserService } from 'src/app/core/services/user.service';
import { PlaylistAddService } from 'src/app/core/services/playlist-add.service';
import { AddToQueueService } from 'src/app/core/services/addtoqueue.service';
import disableScroll from 'disable-scroll';
import { VideoShareService } from 'src/app/core/services/video-share.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-song',
  templateUrl: './song.component.html',
  styleUrls: ['./song.component.scss']
})
export class SongComponent implements OnInit, OnDestroy, AfterViewInit {

  song: any;
  slug: any;
  relatedSongs: any = [];
  final: any;
  showOptions: any = [];
  shareDrop: any;
  songInterval: any;
  playing: any;
  songPlayFlag: any;
  playFlag: any;
  relatedSongsLength: any;
  songsInterval: NodeJS.Timer;
  load: any = false;
  loadData: any = [];
  playerProgressValue = '';
  relatedSongsTotal: any;
  public songIndex: any;
  public morePopUpIndex: any;
  public addMorePopUp: any = false;
  public previousSongIndex: any;
  public queueGetData: any;
  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (this.shareDrop !== undefined) {
      if (event.target !== this.shareDrop) {
        disableScroll.off();
        for (let i = 0; i < this.showOptions.length; i++) {
          this.showOptions[i] = false;
        }
        this.shareDrop = undefined;
      }
    }
  }
  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private audioService: AudioService,
    private router: Router,
    private userService: UserService,
    private playlistService: PlaylistAddService,
    private videoShareService: VideoShareService,
    private AddToQueueService: AddToQueueService,
    private LoaderService: LoaderService) {
    this.load = true;
    this.route.params.subscribe(params => {
      this.slug = params['song_slug'];
      if (this.slug.indexOf('?') !== -1) {
        const params1 = this.slug.substring(0, this.slug.indexOf('?'));
        this.router.navigate(['audio/songs/' + params1]);
      } else {
        this.slug = (this.slug.includes(':')) ? this.slug.split(':')[0] : this.slug;
      }
      this.playFlag = false;
      this.songPlayFlag = false;
      this.getSongDetail();
      this.songInterval = setInterval(() => {
        if (this.song) {
          this.audioService.songData$.subscribe(res => {
            if (res !== null) {
              if (this.song.hls_playlist_url === res.src) {
                this.playing = res.src;
                this.songPlayFlag = true;
                clearInterval(this.songInterval);
              } else {
                this.songPlayFlag = false;
              }
            }
          });
        }
      }, 1000);
    });
  }

  ngOnInit() {
    this.songsInterval = setInterval(() => {
      if (this.song) {
        this.audioService.newState$.subscribe(res => {
          if (res === 'play') {
            this.songPlayFlag = true;
            this.playFlag = false;
          } else if (res === 'pause') {
            this.songPlayFlag = false;
            this.playFlag = true;
          } else if (res === 'dispose') {
            this.songPlayFlag = false;
            this.playFlag = false;
            this.songIndex = null;
          }
        });
      } else {
        this.songPlayFlag = false;
        this.playFlag = false;
      }
    }, 1000);

    window.addEventListener('keyup', function (event) {
      if (event.keyCode === 27) {
        this.morePopUpIndex = null;
      }
    }.bind(this));

    const allMenu = document.querySelector('.main');
    allMenu.addEventListener('click', function (e) {
      if (e.target.id !== 'dots') {
        this.morePopUpIndex = null;
      }
    }.bind(this));

    this.playlistService.display.subscribe(data => {
      if (data === 'close') {
        this.morePopUpIndex = null;
      }
    });

    this.audioService.songState$.subscribe(res => {
      if (res === 'play') {
        if (this.songIndex !== null) {
          this.previousSongIndex = this.songIndex;
        } else if (this.songIndex === null) {
          this.songIndex = this.previousSongIndex;
        }
      } else if (res === 'pause') {
        this.previousSongIndex = this.songIndex;
        this.songIndex = null;
      }
    });
    this.loaderData();
  }
  getSongDetail() {
    this.apiService.callGetAPI('v2/song?slug=' + this.slug).subscribe(res => {
      this.song = res['response'].track_details[0];
      this.openAddToQueue(this.slug, 3, 'song');
      this.relatedSongs = res['response'].related_tracks.data;
      this.relatedSongsTotal = res['response'].related_tracks.total;
      this.relatedSongsLength = this.relatedSongs.length;
      for (const data of this.relatedSongs) {
        this.showOptions.push(false);
      }
      this.final = res['response'].related_tracks.next_page_url ? true : false;
      this.load = false;
      this.playerEvents();
      this.updateSongIndex();
    }, error => {
      this.apiService.failureResponse(error);
      this.load = false;
    });
  }

  pause() {
    this.audioService.getPlayerVariable().pause();
    this.songPlayFlag = false;
    this.playFlag = true;
  }
  resume() {
    this.songPlayFlag = true;
    this.playFlag = false;
    this.audioService.getPlayerVariable().play();
  }
  play() {
    this.audioService.setData({
      'songList': [this.song],
      'selectedindex': 0
    });
    this.audioService.newStateSong('play');
  }
  playSong(i) {
    this.audioService.setData({
      'songList': this.relatedSongs,
      'selectedindex': i
    });
    this.audioService.newStateSong('play');
  }
  goToArtist() {
    this.router.navigate(['audio/artist-detail/' + this.song.artist_slug]);
  }
  goToSong(i) {
    this.router.navigate(['audio/songs/' + this.relatedSongs[i].slug]);
  }
  openSharePopup() {
    this.videoShareService.displayPopup(this.song);
    this.videoShareService.displayPopupBlur(2);
  }
  shareSong(index) {
    this.videoShareService.displayPopup(this.relatedSongs[index]);
    this.videoShareService.displayPopupBlur(2);
  }
  openPlalistSongPopup(index) {
    if (localStorage.getItem('currentUser')) {
      this.playlistService.displayPopup(this.relatedSongs[index]);
      this.videoShareService.displayPopupBlur(2);
    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup(1);
      this.userService.displayPopupBlur(9);
    }
  }
  showOption(index, event) {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    for (let i = 0; i < this.showOptions.length; i++) {
      if (index !== i) {
        this.showOptions[i] = false;
      }
    }
    this.shareDrop = event.target;
    this.showOptions[index] = !this.showOptions[index];
  }
  favouriteSong() {
    if (localStorage.getItem('currentUser')) {
      if (this.song.is_favourite === 0 || this.song.is_favourite === undefined) {
        this.apiService.callPostAPI('v2/audio-favourite?slug=' + this.song.slug, {}).subscribe(res => {
          this.audioService.triggerSongFavourite(this.song.hls_playlist_url);
          this.apiService.successResponse(res);
          this.song.is_favourite = 1;
        },
          error => {
            this.apiService.failureResponse(error);
          });
      } else {
        this.apiService.callPutAPI('v2/audio-favourite?slug=' + this.song.slug, {}).subscribe(res => {
          this.audioService.triggerSongFavourite(this.song.hls_playlist_url);
          this.apiService.successResponse(res);
          this.song.is_favourite = 0;
        },
          error => {
            this.apiService.failureResponse(error);
          });
      }
    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup(1);
      this.userService.displayPopupBlur(9);
    }
  }

  favouriteSongList(index) {
    if (localStorage.getItem('currentUser')) {
      if (this.relatedSongs[index].is_favourite === 0 || this.relatedSongs[index].is_favourite === undefined) {
        this.apiService.callPostAPI('v2/audio-favourite?slug=' + this.relatedSongs[index].slug, {}).subscribe(res => {
          this.audioService.triggerSongFavourite(this.relatedSongs[index].hls_playlist_url);
          this.apiService.successResponse(res);
          this.relatedSongs[index].is_favourite = 1;
        },
          error => {
            this.apiService.failureResponse(error);
          });
      } else {
        this.apiService.callPutAPI('v2/audio-favourite?slug=' + this.relatedSongs[index].slug, {}).subscribe(res => {
          this.audioService.triggerSongFavourite(this.relatedSongs[index].hls_playlist_url);
          this.apiService.successResponse(res);
          this.relatedSongs[index].is_favourite = 0;
        },
          error => {
            this.apiService.failureResponse(error);
          });
      }
    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup(1);
      this.userService.displayPopupBlur(9);
    }
  }
  openPlaylistPopup(index?: any) {
    let trackData;
    if (localStorage.getItem('currentUser')) {
      trackData = (index === undefined) ? this.song : this.relatedSongs[index];
      this.playlistService.displayPopup(trackData);
      this.videoShareService.displayPopupBlur(2);

    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup(1);
      this.userService.displayPopupBlur(9);
    }
  }
  openAddToQueue(index, value, type) {
    this.userService.redirectUrl(this.router.url);
    /******2- song,1- album******/
    if (value === 2) {
      if (this.queueGetData !== null) {
        this.queueGetData.push(this.relatedSongs[index]);
      } else {
        this.queueGetData = [];
        this.queueGetData.push(this.relatedSongs[index]);
      }
      localStorage.setItem('queueList', JSON.stringify(this.queueGetData));
      this.apiService.customSuccessResponse('Song has been added to queue Successfully');
      this.morePopUpIndex = null;
    } else if (value === 3) {
      if (this.queueGetData !== null) {
        this.relatedSongs.forEach((element, index) => {
          this.queueGetData.push(element);
        });
      } else {
        this.queueGetData = [];
        this.relatedSongs.forEach((element, index) => {
          this.queueGetData.push(element);
        });
      }
      localStorage.setItem('queueList', JSON.stringify(this.queueGetData));
    } else {
      this.AddToQueueService.displayPopup(type + '?' + this.slug);
      this.videoShareService.displayPopupBlur(2);
    }
  }
  openMorePopUp(index) {
    if (this.morePopUpIndex === index) {
      this.addMorePopUp = false;
    } else {
      this.addMorePopUp = true;
    }
    if (this.addMorePopUp === true) {
      this.morePopUpIndex = index;
    } else {
      this.morePopUpIndex = null;
    }
  }
  ngOnDestroy() {
    clearInterval(this.songsInterval);
    clearInterval(this.songInterval);
  }
  ngAfterViewInit() {
    this.queueGetData = JSON.parse(localStorage.getItem('queueList'));
  }
  loaderData() {
    for (let i = 0; i < 6; i++) {
      this.loadData.push({ 'i': i });
    }
    return this.loadData;
  }
  routeTo(path) {
    this.router.navigate([path]);
  }

  playerEvents() {
    this.audioService.currentplayingSongIdVar$.subscribe((id) => {
      if (this.relatedSongs) {
        this.songIndex = this.relatedSongs.findIndex((value) => value.id === id);
      }
    });

    this.audioService.playerProgressVar$.subscribe((progress) => {


      if (this.song && progress) {

        if (this.song.hls_playlist_url === progress.currentSrc) {
          this.playerProgressValue = `${progress.progressFinal}%`;
        }
      }
    });
  }

  updateSongIndex() {
    const id = this.audioService.getCurrentPlayingSongId();
    this.songIndex = this.relatedSongs.findIndex((value) => value.id === id);
  }
  isEmptyObject(data) {
    return (data && (Object.keys(data).length === 0));
  }
}
