import { Component, OnInit, HostListener, Output, Input, EventEmitter } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent implements OnInit {
  notifications: any = [];
  page = 0;
  removeSingleNotify: any = [];
  @Output() notifyRead = new EventEmitter;
  @Input() lang: any;
  final = false;
  flag = false;
  loading = false;
  @HostListener('scroll', ['$event'])
  onScroll(event) {
    const el = document.getElementById('notification_bottom');
    if (this.isElementInViewport(el) && this.flag === false && this.final === false) {
      this.flag = true;
      this.getNotifications();
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.className === 'nuc') {
      let url = event.target.closest('.notify-msg').lastChild.innerText;
      if (url.includes(window.location.origin)) {
        url = url.replace(window.location.origin, '');
        this.router.navigate([url]);
      } else {
        window.location = event.target.closest('.notify-msg').lastChild.innerText;
      }
    }
  }

  @HostListener('change') ngOnChanges() {
    this.langChange();
  }
  constructor(private apiService: ApiService, private router: Router, private translate: TranslateService) { }

  ngOnInit() {
    this.translate.use(localStorage.getItem('currentLanguage'));
    this.getNotifications();
  }

  isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
  }

  getNotifications() {
    if (!this.loading) {
      this.loading = true;
      this.page += 1;
      this.apiService.callGetAPI('v2/notifications?page=' + this.page, environment.notificationApiUrl)
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (this.page === 1) {
                this.notifications = data['response'].data;
              } else {
                this.notifications = this.notifications.concat(data['response'].data);
              }
            }
            this.final = (data['response'].next_page_url === null) ? true : false;
            this.flag = false;
            this.loading = false;
          },
          error => {
            this.apiService.failureResponse(error, true);
          });
    }
  }

  notificationRemoveAll() {
    this.apiService.callGetAPI('v2/notification/remove_all', environment.notificationApiUrl)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.notifications = [];
          }
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

  mouseover(index) {
    this.removeSingleNotify[index] = true;
  }

  mouseout(index) {
    this.removeSingleNotify[index] = false;
  }

  removeSingleNotification(index) {
    this.apiService.callGetAPI('v2/notification/remove/' + this.notifications[index]._id, environment.notificationApiUrl)
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.notifications.splice(index, 1);
          }
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

  readSingleNotification(index) {
    if (this.notifications[index].read_at === null) {
      this.apiService.callGetAPI('v2/notification/read/' + this.notifications[index]._id, environment.notificationApiUrl)
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              this.notifications[index].read_at = 'read';
              this.notificationRead(index);
            }
          },
          error => {
            this.apiService.failureResponse(error, true);
          });
    } else {
      this.notificationRead(index);
    }
  }

  returnUrl(content) {
    // return content;
    let data = '';
    let link = '';
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    data = content.replace(urlRegex, function (url) {
      link = url;
      return url;
    });
    return link;
  }
  urlFinder(content) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.replace(urlRegex, function (url) {
      return '<span class="nuc">' + url + '</span>';
    });
  }

  notificationRead(index) {
    this.notifyRead.emit();
    if (this.notifications[index].video.is_story) {
      this.router.navigate(['story-detail/' + this.notifications[index].video.slug]);
    } else {
      this.router.navigate(['video/' + this.notifications[index].video.slug]);
    }
  }
  langChange() {
    this.page = 0;
    this.notifications = [];
    this.ngOnInit();
  }
}
