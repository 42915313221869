import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HeaderService {

  private apiData = new BehaviorSubject<any>(null);
  public apiData$ = this.apiData.asObservable();
  private bannerData = new BehaviorSubject<any>(null);
  public bannerData$ = this.bannerData.asObservable();
  private newData = new BehaviorSubject<any>(null);
  public newData$ = this.newData.asObservable();
  private webseriesData = new BehaviorSubject<any>(null);
  public webseriesData$ = this.webseriesData.asObservable();
  private recentData = new BehaviorSubject<any>(null);
  public recentData$ = this.recentData.asObservable();
  private home_playlist = new BehaviorSubject<any>(null);
  public home_playlist$ = this.home_playlist.asObservable();
  private albumData = new BehaviorSubject<any>(null);
  public albumData$ = this.albumData.asObservable();
  public homeCategoryData;
  private mainmenuCategoryData = new BehaviorSubject<any>(null);
  public mainmenuCategoryData$ = this.mainmenuCategoryData.asObservable();
  public adminPlaylistData;
  public adminTilePlaylistData;
  public adminShortsPlaylistData;
  constructor(private apiService: ApiService) { }

  fetchData() {
    return this.apiService.callGetAPI('v2/footer', environment.commonApiUrl).pipe(map((data) => {
      return data;
    }));
  }
  setData(data) {
    this.apiData.next(data);
  }

  fetchBanner() {
    this.apiService.callGetAPI('v2/home_page_dashboard')
      .subscribe(
        data => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.webseriesData.next(data['response'].webseries);
            this.bannerData.next(data['response'].banner_new);
            this.newData.next(data['response'].new);
            this.recentData.next(data['response'].recent);
            this.home_playlist.next(data['response'].home_playlist);
          }
        },
        error => {
          this.apiService.failureResponse(error, true);
        });
  }

  fetchMainmenuCategories() {
    return this.apiService.callGetAPI('v2/menu-categories', environment.commonApiUrl).pipe(map((data) => {
      return data;
    }));
  }

  setHomeCategory(data) {
    this.homeCategoryData = data;
  }

  getHomeCategory() {
    return this.homeCategoryData;
  }

  setMainMenuCategory(data) {
    this.mainmenuCategoryData.next(data);
  }

  fetchAlbumListData() {
    const apiUrl = 'v2/browse?search=' + 'all' + '&page=' + 1;
    return this.apiService.callGetAPI(apiUrl)
      .subscribe(data => {
        this.albumData.next(data);
      },
        error => {
          this.apiService.failureResponse(error);
        });
  }
  setAdminPlaylistData(data) {
    this.adminPlaylistData = data;
  }

  getAdminPlaylistsData() {
    return this.getAdminPlaylistsData;
  }
  setAdminTilePlaylistData(data) {
    this.adminTilePlaylistData = data;
  }

  getAdminTilePlaylistsData() {
    return this.getAdminTilePlaylistsData;
  }

  setAdminShortsPlaylistData(data) {
    this.adminShortsPlaylistData = data;
  }

  getAdminShortsPlaylistsData() {
    return this.getAdminShortsPlaylistsData;
  }
}
