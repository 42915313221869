import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddToQueueService {

  constructor() { }
  private popup = new BehaviorSubject<any>('close');
  display = this.popup.asObservable();

  // Observable share popup blur details
  private popupBlur = new BehaviorSubject<any>(1);
  displayPopUpBlur = this.popupBlur.asObservable();

  // playlist popup
  displayPopup(data: any) {
    this.popup.next(data);
  }

  // playlist popupBlur
  displayPopupBlur(data: any) {
    this.popupBlur.next(data);
  }
}
