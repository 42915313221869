import { Component } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { environment } from '../../../environments/environment';
import { AudioService } from 'src/app/core/services/audio.service';
import { UserService } from 'src/app/core/services/user.service';
import { AuthService } from '../../core/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})

export class RadioComponent {
  playlists: any = [];
  loading: any = false;
  page: any = 0;
  loadingSlider: any = [];
  playpauseRadio: any = true;
  constructor(private apiService: ApiService,
    private audioService: AudioService, private userService: UserService, private authService: AuthService, private router: Router) {
    // this.audioService.openOrClose(0);
  }

  ngOnInit() {
    if (document.querySelector('body').className.includes('audio-playing')) {
      $('.radio-overly').css({ 'display': 'block' });
    } else {
      $('.radio-overly').css({ 'display': 'block' });
    }
    this.loadingSlider.data = [];

    for (let i = 0; i <= 4; i++) {
      this.loadingSlider.data.push({ 'thumbnail_image': environment.defaultImage });
    }
    this.getAudioPlaylists();
  }

  radioAction() {
    $('.slider-img').removeClass('current-playing-radio');
    this.playpauseRadio = this.playpauseRadio ? false : true;
    console.log('this.playpauseRadio',this.playpauseRadio);
    this.audioService.openOrClose(this.playpauseRadio ? 1 : 0);
  }

  getAudioPlaylists() {
    this.page++;
    this.loading = this.playlists ? false : true;
    this.apiService.callGetAPI('v2/playlist_browse?page=' + this.page, environment.apiUrl).subscribe(data => {
      this.loading = false;
      if (this.page !== 1) {
        data['response'].playlists.data = [...this.playlists.data, ...data['response'].playlists.data];
      }
      this.playlists = data['response'].playlists;
    }, error => {
      this.loading = false;
      console.log('err', error);
    });
  }

  next(e) {
    if (this.playlists.last_page > this.page) {
      this.getAudioPlaylists();
    }
  }

  playlistClick(i, data, index) {
    if(this.checkUser() && !this.loading){
      this.loading = true;
      this.updateRadioIndex(null);
      $(`.${i} img`).addClass('loading-image');
      $(`.${i} .radio-playlist-loading`).css({'display':'block'});
      this.apiService.callGetAPI('v2/playlists_details?slug=' + data.playlist_slug).subscribe(res => {
        this.loading = false;
        if (res) {
          localStorage.setItem('queueList', JSON.stringify(res['response'].playlist_info.playlist_tracks));
          this.audioService.setData({
            'songList': res['response'].playlist_info.playlist_tracks,
            'selectedindex': 0
          });
          this.playpauseRadio = true;
          this.audioService.openOrClose(1);
          $(`.${i} img`).removeClass('loading-image');
          $(`.${i} .radio-playlist-loading`).css({'display':'none'});
          $('.slider-img').removeClass('current-playing-radio');
          $(`.${i} .slider-img`).addClass('current-playing-radio');
          this.updateRadioIndex(index);
        }
      },
        error => {
          this.loading = false;
          this.apiService.failureResponse(error);
          $(`.${i} img`).removeClass('loading-image');
          $(`.${i} .radio-playlist-loading`).css({'display':'none'});
        });
    }
  }

  updateRadioIndex(id) {
    this.audioService.setCurrentPlayingRadioId(id);
  }

  checkUser() {
    if (!localStorage.getItem('currentUser')) {
      this.userService.displayPopup('sign-in');
      return false;
    } else if (localStorage.getItem('currentUser')) {
      const currentUser = this.authService.decryption(localStorage.getItem('currentUser'));
      if (currentUser && currentUser['response'] && currentUser['response'].is_subscribed) {
        return true;
      } else {
        this.router.navigate(['/subscription']);
      }
    }
  }

  ngOnDestroy() {
    // this.audioService.openOrClose(1);
  }
}
