import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../core/services/api.service";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-shorts',
  templateUrl: './shorts.component.html',
  styleUrls: ['./shorts.component.scss']
})
export class ShortsComponent implements OnInit {
  banner: any = [];
  shortsSection: any = [];
  shortsPopular: any = [];
  loader: boolean = false;
  shorts_page: boolean = true;
  loadingSlider: any = [];
  constructor(private apiService: ApiService,) { }

  ngOnInit() {
    this.loadingSlider.data = [];
    for (let i = 0; i <= 4; i++) {
      this.loadingSlider.data.push({ 'thumbnail_image': environment.defaultImage });
    }
    //this.getBanner();
    this.getShortsSections();
  }

  getBanner = () => {
    this.apiService
      .callGetAPI("v2/get_shorts_banner")
      .subscribe(
        (data) => {
          //console.log(data);
          if (data['response'].banner) {
            this.banner = data['response'].banner.data;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  getShortsSections = () => {
    this.loader = true;
    this.apiService
      .callGetAPI("v2/shorts_page")
      .subscribe(
        (data) => {
          //console.log(data);
          if (data['response']) {
            this.shortsSection = Object.values(data['response'].shorts_content);
            //this.shortsPopular = data['response'].popular_shorts;
          }
          //console.log('shortsSection', this.shortsPopular)
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  checkData = data => {
    //console.log(data);
    return 'test';
  }

}
