import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../core/services/api.service";
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'app-advisor',
  templateUrl: './advisor.component.html',
  styleUrls: ['./advisor.component.scss']
})
export class AdvisorComponent implements OnInit {
  userDetails: any = [];
  loader: boolean = false;
  formData: any = {};
  formError: string = "";
  notificationStatus: string = "";

  constructor(private apiService: ApiService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.getAdviser();
    this.notificationStatus = "";
  }

  getAdviser = () => {
    this.loader = true;
    this.apiService
      .callGetAPI("v2/customer/subscription", environment.userApiUrl)
      .subscribe(
        (data) => {
          this.loader = false;
          if (data['response'].is_adviser != 1) {
            this.router.navigate(['404']);
            return false;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  getUserDetails = (knid: number) => {
    this.notificationStatus = "";
    this.loader = true;
    this.apiService
      .callGetAPI("v2/customer/details/" + knid, environment.userApiUrl)
      .subscribe(
        (data) => {
          this.loader = false;
          if (data['response']) {
            this.userDetails = data['response'];
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  onSubmit() {

    if (this.formData.knid == undefined || this.formData.knid == "") {
      this.formError = "";
      this.userDetails = {};
    } else {
      this.formError = "";
      this.getUserDetails(this.formData.knid);
    }
  }


  checkData = data => {
    console.log(data);
    return 'test';
  }

  startConversation(campaignName: any, e: any, btn: any) {
    this.notificationStatus = "";
    let notificationStatus = "";
    let templateParam = [];
    let userData: any = {};
    let advisorName = "";
    const userValues = localStorage.getItem('currentUser');
    if (userValues) {
      userData = this.authService.decryption(userValues);
      advisorName = userData.response.name;
    }
    if (btn == 2) {
      notificationStatus = 'Missed call message sent successfully.';
      templateParam = [e.name, e.name, String(e.id), advisorName];
    } else {
      templateParam = [e.name, String(e.id), advisorName];
      notificationStatus = 'Start conversation message sent successfully.';
    }
    const apiUrl = 'v2/send-whatsapp';
    this.loader = true;
    this.apiService
      .callPostAPI(apiUrl,
        {
          'campaignName': campaignName,
          'destination': String(e.phone),
          'templateParams': templateParam,
        }, environment.userApiUrl)
      .subscribe(
        (data) => {
          this.loader = false;
          if (data['statusCode'] == 200) {
            this.notificationStatus = notificationStatus;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }

      );
  }


}
