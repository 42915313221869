import { Component, OnInit, HostListener, AfterViewInit, AfterContentInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { TranslateService } from '@ngx-translate/core';
import { AudioService } from 'src/app/core/services/audio.service';
import { UserService } from 'src/app/core/services/user.service';
import { VideoShareService } from 'src/app/core/services/video-share.service';
import { PlaylistAddService } from 'src/app/core/services/playlist-add.service';
// import videojs from 'src/assets/js/play.es.js';
import videojs from 'src/assets/js/play-audio.es.js';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, AfterViewInit, AfterContentInit {
  date = new Date();
  year = this.date.getFullYear();
  categories: any = [];
  userValues: string;
  is_subscribed: any;
  contact: any;
  cmsLink: any = [];
  sub: any;

  site_link: any = [];
  language: any;
  footerFlag: any = false;

  routes: any;
  shows: any;
  playerFlag: any = false;
  public trackList = [];
  public sendHistory = true;
  public player: any;
  routeFlag: string;
  currentUrl: any;

  @HostListener('document:click', ['$event'])
  clickout(event) {
   /*  if(event.target.className.includes('stop-btn')){
      this.player.pause();
      this.player.currentTime(0)
      $('.stop-play').css({'background':'#ea4e2ad1'});
    } */
    this.changeText();
  }

  constructor(private apiService: ApiService,
    private router: Router,
    private authService: AuthService,
    private audioService: AudioService,
    private userService: UserService,
    private translate: TranslateService,
    private shareService: VideoShareService,
    private playlistService: PlaylistAddService,

    private headerService: HeaderService) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
      }
    });
  }

  ngOnInit() {
    this.routeFlag = this.router.url;
    if (localStorage.getItem('currentLanguage')) {
      this.language = localStorage.getItem('currentLanguage');
    } else {
      this.language = 'en';
    }
    // const footerHeight =  window.getComputedStyle(document.getElementById('footer'));
    // const fheight = footerHeight.getPropertyValue('height');
    // document.body.style.paddingBottom = fheight;

    this.audioService.opencloseplayer$.subscribe((data) => {
      console.log('close player');
      if (data === 1) {
        this.loadAudio();
      } else if (data === 0) {
        this.closePlayer(false);
      }
    });
  }
  
  ngAfterViewInit() {
    this.getCategories();
  }
  ngAfterContentInit(): void {
    this.footerFlag = true;
    this.loadAudio();
  }
  getCategories() {
    this.headerService.apiData$.subscribe(data => {
      if (data !== null) {
        this.categories = data['category'];
        this.contact = data['response'].address;
        this.cmsLink = data['response'].static_contents;
        this.site_link = data['response'].site_link;
      }
    });
    if (this.categories === null) {
      setTimeout(() => {
        this.getCategories();
      }, 1000);
    }
  }


  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.href = this.site_link.android_app_link ? this.site_link.android_app_link : undefined;
      a.click();
    }
  }
  initAudioPlayer() {
    if (!this.playerFlag) {
      this.player = videojs('audio-player', {
        'srcType': 'audio',
        'fluid': true,
        'sources': [{
          'src': 'https://d6inq6b33hd48.cloudfront.net/encoded/music/Te-Aki1524835081.m3u8',
          'type': 'application/x-mpegURL',
        }],
        'trackList': this.trackList

      });
    }

    this.audioService.setPlayerVariable(this.player);
  }

  changeText() {
    if (document.querySelector('.play-repeat-one-control')) {
      this.translate.get('AUDIO_PLAYER.REPEAT').subscribe(res => {
        (<HTMLElement>document.querySelector('.play-repeat-one-control')).title = res;
      });
    }
    if (document.querySelector('.play-repeat-off-control')) {
      this.translate.get('AUDIO_PLAYER.REPEAT_OFF').subscribe(res => {
        (<HTMLElement>document.querySelector('.play-repeat-off-control')).title = res;
      });
    }

    if (document.querySelector('.play-repeat-control')) {
      this.translate.get('AUDIO_PLAYER.REPEAT_ALL').subscribe(res => {
        (<HTMLElement>document.querySelector('.play-repeat-control')).title = res;
      });
    }

    if (document.querySelector('.play-shuffle-off-control')) {
      this.translate.get('AUDIO_PLAYER.SHUFFLE_OFF').subscribe(res => {
        (<HTMLElement>document.querySelector('.play-shuffle-off-control')).title = res;
      });
    }

    if (document.querySelector('.play-shuffle-control')) {
      this.translate.get('AUDIO_PLAYER.SHUFFLE_ON').subscribe(res => {
        (<HTMLElement>document.querySelector('.play-shuffle-control')).title = res;
      });
    }

    if (document.querySelector('.play-previous-control')) {
      this.translate.get('AUDIO_PLAYER.PREVIOUSE').subscribe(res => {
        (<HTMLElement>document.querySelector('.play-previous-control')).title = res;
      });
    }

    if (document.querySelector('.play-next-control')) {
      this.translate.get('AUDIO_PLAYER.NEXT').subscribe(res => {
        (<HTMLElement>document.querySelector('.play-next-control')).title = res;
      });
    }

    // setTimeout(function () {
    //   if (document.querySelector('.play-paused')) {
    //     this.translate.get('AUDIO_PLAYER.PAUSED').subscribe(res => {
    //       (<HTMLElement>document.querySelector('.play-paused')).title = res;
    //     });
    //   }

    //   if (document.querySelector('.play-playing')) {
    //     this.translate.get('AUDIO_PLAYER.PLAYING').subscribe(res => {
    //       (<HTMLElement>document.querySelector('.play-playing')).title = res;
    //     });
    //   }

    //   if (document.querySelector('.play-vol-3')) {
    //     this.translate.get('AUDIO_PLAYER.MUTE').subscribe(res => {
    //       (<HTMLElement>document.querySelector('.play-vol-3')).title = res;
    //     });
    //   }

    //   if (document.querySelector('.play-vol-0')) {
    //     this.translate.get('AUDIO_PLAYER.UNMUTE').subscribe(res => {
    //       (<HTMLElement>document.querySelector('.play-vol-0')).title = res;
    //     });
    //   }
    // }, 5000);
    // play-repeat-off-control
  }

  audioPlayerEvents() {
    // check the type is audio or radio
    let temp = this.trackList;
    temp.map((value,i) => {
      if(value.src === this.player['cache_'].source.src){
        if(value.is_radio === 1){
          this.updatePlayerData(true);
        } else {
          this.updatePlayerData();
        }
        return;
      }
    });

    // change tooletip text
    const elements = document.getElementsByTagName('body')[0];
    elements.classList.add('audio-started');
    this.player.on('playing', () => {
      console.log('playerrr',this.player);
      console.log('this.trackList',this.trackList)
      let currentSong;
      const currentSongId = this.trackList.filter((value) => {
        console.log('dataaaaa', value, this.player['cache_'].source.src, value.src)
        if(value.src === this.player['cache_'].source.src){
          currentSong = value;
          return value.src === this.player['cache_'].source.src
        }
      });
      console.log('currentSongId',currentSongId)
      const id = [...currentSongId][0].id;
      console.log('id footer', id);
      if(currentSong.is_radio){
        console.log('ifff')
        this.audioService.setCurrentPlayingRadioId(id);
        this.audioService.setCurrentPlayingSongId(null);
        this.audioService.songState('play');
        this.audioService.setStatus(this.player.cache_.source);
      } else {
        console.log('elseee')
        this.audioService.setCurrentPlayingSongId(id);
        this.audioService.setCurrentPlayingRadioId(null);
        this.audioService.songState('play');
        this.audioService.setStatus(this.player.cache_.source);
      }
      /* if($('.stop-btn').html()){
        $('.stop-play').css({'background':'white'});
      } */
      if (this.sendHistory) {
        this.sendHistory = false;
        this.apiService.callPostAPI('v2/audio-play-history', { audio: [...currentSongId][0].slug }).subscribe(res => {
        },
          error => {
            this.apiService.failureResponse(error);
          });
      }
      document.querySelector('body').classList.add('audio-playing');
    });
    this.player.on('pause', () => {
      this.audioService.newStateSong('pause');
      this.audioService.songState('pause');
      document.querySelector('body').classList.remove('audio-playing');
    });

    this.player.on('prevClicked', () => {
      this.sendHistory = true;
    });
    this.player.on('nextClicked', () => {
      this.sendHistory = true;
    });

    this.player.on('repeatClick', () => {
      console.log('repeat cliecked')
    });

    this.player.on('favourite_true', (event) => {
      const loggedIn = localStorage.getItem('currentUser');
      this.trackList.forEach((datas, index) => {
        if (datas.src === this.player['cache_'].source.src) {
          if (loggedIn) {
            if (datas.is_favourite === 0 || datas.is_favourite === undefined) {
              this.apiService.callPostAPI('v2/audio-favourite?slug=' + datas.slug, {}).subscribe(res => {
                this.apiService.successResponse(res);
                datas.is_favourite = 1;
                this.trackList[index].is_favourite = 1;
              },
                error => {
                  this.apiService.failureResponse(error);
                });
            }
          } else {
            this.userService.redirectUrl(this.router.url);
            this.userService.displayPopup(1);
            this.userService.displayPopupBlur(9);
            this.userService.displayPopup('sign-in');
          }
        }
      });


    });
    this.player.on('favourite_false', (event) => {
      const loggedIn = localStorage.getItem('currentUser');
      for (const datas of this.trackList) {
        if (datas.src === this.player['cache_'].source.src) {
          if (loggedIn) {
            this.apiService.callPutAPI('v2/audio-favourite?slug=' + datas.slug, {}).subscribe(res => {
              this.apiService.successResponse(res);
              datas.is_favourite = 0;
            },
              error => {
                this.apiService.failureResponse(error);
              });
          } else {
            this.userService.redirectUrl(this.router.url);
            this.userService.displayPopup(1);
            this.userService.displayPopupBlur(9);
            this.userService.displayPopup('sign-in');
            break;
          }
        }
      }
    });
    this.player.on('share', (event) => {
      for (const datas of this.trackList) {
        if (datas.src === this.player['cache_'].source.src) {
          this.shareService.displayPopup(datas);
          this.shareService.displayPopupBlur(2);
          break;
        }
      }
    });
    this.player.on('playlist', (event) => {
      for (const datas of this.trackList) {
        if (datas.src === this.player['cache_'].source.src) {
          this.playlistService.displayPopup(datas);
          this.shareService.displayPopupBlur(2);
          break;
        }
      }
    });

    this.audioService.setFavVar$.subscribe((data) => {
      if (data) {
        this.player.trigger({
          type: 'clickFavourite',
          src: data
        });
      }
    });

    this.player.on('progressWidth', (e) => {
      this.audioService.updatePlayerProgress(e);
    });
  }

  updatePlayerData(radio = false){
    if(radio){
      $('.play-previous-control').css({'display':'none'});
      $('.play-next-control').css({'display':'none'});
      $('.play-playlist-control').css({'display':'none'});
      $('.play-repeat-class, .play-shuffle-off-control, .play-shuffle-control').addClass('radio-control');
      $('.play-progress-control').css({'display':'none'});
      $('#thumb').css({'display':'none'});
      $('#audio-player .play-current-time').css({'display':'none'});
      $('#audio-player .play-duration').css({'display':'none'});
      $('.play-repeat-one-control').click();
      // if(!$('.stop-btn').html()){
      //   $( "#audio-player .play-play-control" ).after('<div class="stop-btn" style="background: linear-gradient(to right, rgb(241, 108, 31) 0%, rgb(251, 133, 65) 100%);width: 30px;height: 30px;border-radius: 5px;display: block;cursor: pointer;position: absolute;right: 45%;top: 36%;"><div class="stop-btn stop-play" style="background: white;width: 10px;height: 10px;margin: 10px;"></div></div>');
      // }
      // $('.stop-btn').css({'display':'block'});
    } else {
      $('.play-previous-control').css({'display':'block'});
      $('.play-next-control').css({'display':'block'});
      $('.play-playlist-control').css({'display':'block'});
      $('.play-repeat-class, .play-shuffle-off-control, .play-shuffle-control').removeClass('radio-control');
      $('.play-progress-control').css({'display':'block'});
      $('#thumb').css({'display':'block'});
      $('#audio-player .play-current-time').css({'display':'block'});
      $('#audio-player .play-duration').css({'display':'block'});
      $('.play-repeat-control').click();
      // $('.stop-btn').css({'display':'none'});
    }
  }

  closePlayer(removequeue = true) {
    if(this.player){
      $('.play-shuffle-control').click();
      this.playerFlag = false;
      this.trackList.length = 0;
      this.player.pause();
      this.audioService.newStateSong('pause');
      this.audioService.songState('pause');
      this.audioService.setCurrentPlayingSongId(null);
      this.audioService.setCurrentPlayingRadioId(null);
      const elements = document.getElementsByTagName('body')[0];
      elements.classList.remove('audio-started');
      if (removequeue) {
        localStorage.removeItem('queueList');
      }
    }
  }

  loadAudio() {

    console.log(1);
    this.sub = this.audioService.apiData$.subscribe(res => {
      if (res && localStorage.getItem('queueList')) {
        console.log('apiData', res);
        if (res.selectedindex !== null) {
          this.trackList.length = 0;
          this.trackList.unshift(...res.songList.map((val) => {
            return {
              'id': val.id,
              'src': val.hasOwnProperty('hls_playlist_url') ? val.hls_playlist_url : val.src,
              'type': 'application/x-mpegURL',
              'title': val.hasOwnProperty('audio_title') ? val.audio_title : val.title,
              'authorTitle': val.hasOwnProperty('artist_name') ? val.artist_name : val.authorTitle,
              'thumbnails': val.audio_thumbnail,
              'favourite': val.is_favourite ? true : false,
              'slug': val.slug,
              'audio_thumbnail': val.audio_thumbnail,
              'audio_title': val.audio_title,
              'artist_name': val.artist_name,
              'album_name': val.album_name,
              'is_radio': val.is_radio,
              'radio_name': val.is_radio ? val.radio_name : null
            };
          }));

          this.sendHistory = true;
          this.initAudioPlayer();
          this.player.trackListIndex = res.selectedindex;
          this.player.trigger('TrackPush');
          this.audioPlayerEvents();
          this.playerFlag = true;
          this.audioService.setQueueData(this.trackList);
        } else {
          if (this.trackList.length > 0) {
            this.trackList.push(res.songList);
          } else {
            this.trackList.push(res.songList);
            this.sendHistory = true;
            this.initAudioPlayer();
            this.player.trackListIndex = 0;
            this.player.trigger('TrackPush');
            this.audioPlayerEvents();
            this.playerFlag = true;
            this.audioService.setQueueData(this.trackList);
          }
        }
      }
    });
console.log('this.routeFlag',this.routeFlag);
    if (this.routeFlag.startsWith('/audio') && this.routeFlag != '/audio-playlist-all') {
      console.log('pause here')
      this.playerFlag = false;
      this.trackList.length = 0;
      this.player.pause();
      this.audioService.newStateSong('pause');
      this.audioService.songState('pause');
      this.audioService.setCurrentPlayingSongId(null);
      this.audioService.setCurrentPlayingRadioId(null);
      const elements = document.getElementsByTagName('body')[0];
      elements.classList.remove('audio-started');
    }
  }

}
