import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-playlist-card',
  templateUrl: './admin-playlist-card.component.html',
  styleUrls: ['./admin-playlist-card.component.scss']
})
export class AdminPlaylistCardComponent implements OnInit {
  @Input() playlists: any = {};
  @Input() type: string;
  slidItemClass = false;
  constructor(private router: Router) { }

  ngOnInit() {
  }
  playlistClick(playlistData) {
    this.router.navigate(['playlist/' + playlistData.slug]);
  }
}
