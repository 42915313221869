import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgetPasswordComponent } from './forget-password.component';
import { ForgetPasswordRoutingModule } from './forget-password-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ForgetPasswordRoutingModule,
    TranslateModule,
    FormsModule
  ],
  declarations: [
    ForgetPasswordComponent
  ],
  exports: [
    ForgetPasswordComponent
  ]
})
export class ForgetPasswordModule { }
