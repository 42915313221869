import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoCardComponent } from './components/video/video-card/video-card.component';
import { TrailerCardComponent } from './components/video/trailer-card/trailer-card.component';
import { TrailerCardShortsComponent } from './components/video/trailer-card-shorts/trailer-card-shorts.component';
import { PriceCardComponent } from './components/price-card/price-card.component';
import { LoadmoreComponent } from './components/loadmore/loadmore.component';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { Safe } from './safeHtml';
import { ViewCount } from './view-count';
import { NoVideosComponent } from './components/no-videos/no-videos.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContusSliderComponent } from '../modules/contus-slider/contus-slider.component';
import { LoaderCardComponent } from './components/video/loader-card/loader-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { StringRemoveWhitespace } from './stringremovewhitespace';
import { CouponComponent } from './components/coupon/coupon.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { SearchSearchVideoCardComponent } from './components/video/search-video-card/search-video-card.component';
import { AdminPlaylistCardComponent } from './components/video/admin-playlist-card/admin-playlist-card.component';
import { AdminTilePlaylistCardComponent } from './components/video/admin-tile-playlist-card/admin-tile-playlist-card.component';
import { AdminShortsPlaylistCardComponent } from './components/video/admin-shorts-playlist-card/admin-shorts-playlist-card.component';
import { AudioPlaylistCardComponent } from './components/video/audio-playlist-card/audio-playlist-card.component';
import { LuhSliderComponent } from './components/luh-slider/luh-slider.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { SafeUrl } from './safeUrl';
import { NoAudiosComponent } from './components/no-audios/no-audios.component';

@NgModule({
  imports: [
    CommonModule,
    LazyLoadImagesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    HighchartsChartModule
  ],
  declarations: [
    VideoCardComponent,
    TrailerCardComponent,
    TrailerCardShortsComponent,
    PriceCardComponent,
    LoadmoreComponent,
    Safe,
    SafeUrl,
    ViewCount,
    NoVideosComponent,
    ContusSliderComponent,
    LoaderCardComponent,
    StringRemoveWhitespace,
    CouponComponent,
    AnalyticsComponent,
    SearchSearchVideoCardComponent,
    AdminPlaylistCardComponent,
    AdminTilePlaylistCardComponent,
    AdminShortsPlaylistCardComponent,
    AudioPlaylistCardComponent,
    LuhSliderComponent,
    NoAudiosComponent
  ],
  exports: [
    VideoCardComponent,
    TrailerCardComponent,
    TrailerCardShortsComponent,
    PriceCardComponent,
    LoadmoreComponent,
    Safe,
    SafeUrl,
    ViewCount,
    NoVideosComponent,
    ContusSliderComponent,
    LoaderCardComponent,
    CouponComponent,
    AnalyticsComponent,
    SearchSearchVideoCardComponent,
    AdminPlaylistCardComponent,
    AdminTilePlaylistCardComponent,
    AdminShortsPlaylistCardComponent,
    AudioPlaylistCardComponent,
    LuhSliderComponent
  ]
})
export class SharedModule { }
