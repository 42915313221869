import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-tile-playlist-card',
  templateUrl: './admin-tile-playlist-card.component.html',
  styleUrls: ['./admin-tile-playlist-card.component.scss']
})
export class AdminTilePlaylistCardComponent implements OnInit {
  @Input() tile_playlists: any = {};
  @Input() type: string;
  slidItemClass = false;
  constructor(private router: Router) { }

  ngOnInit() {
  }
  playlistClick(playlistData) {
    if(playlistData.slug == 'radio'){
      this.router.navigate(['radio']);
    }else{
      this.router.navigate(['category/' + playlistData.slug]);
    }
    
  }
}
