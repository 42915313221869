import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInRoutingModule } from './sign-in-routing.module';
import { SignInComponent } from './sign-in.component';
import { FormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { SocialLoginModule } from '../social-login/social-login.module';

@NgModule({
  imports: [
    CommonModule,
    SignInRoutingModule,
    FormsModule,
    TranslateModule,
    SocialLoginModule
  ],
  declarations: [
    SignInComponent
  ],
  exports: [
    SignInComponent
  ]
})
export class SignInModule { }
