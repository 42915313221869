import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-videos',
  templateUrl: './no-videos.component.html',
  styleUrls: ['./no-videos.component.scss']
})
export class NoVideosComponent implements OnInit {

  @Input() data;
  constructor() { }

  ngOnInit() {
  }

}
