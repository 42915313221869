import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-audios',
  templateUrl: './no-audios.component.html',
  styleUrls: ['./no-audios.component.scss']
})
export class NoAudiosComponent implements OnInit {

  constructor() { }
  @Input() data;
  ngOnInit() {
  }

}
