import { Component, OnInit, OnDestroy, Output, EventEmitter, HostListener, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import videojs from '../../../../assets/js/play.es.js';
// import videojs from 'video.js';
import '../../../../assets/js/videojs-playlist.es.js';
import '../../../../assets/js/videojs-playlist-ui.es.js';
import { PlayerService } from '../../../core/services/player.service';
import { ConnectionService } from 'ng-connection-service';
import { environment } from '../../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../core/auth/auth.service';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import YTPlayer from 'yt-player';


declare var $: any;
@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, OnDestroy {
  enableSubscribe = false;
  isPlaying = false;
  player: any;
  vimdeoPlayer: any;
  time = 0;
  newYoutube: any;
  user: any;
  overlayContent: any;
  status = 'ONLINE';
  geoStatus = true;
  isConnected = true;
  newtworkStatus: any;
  setVideo: Boolean = true;
  params: any;
  @Output() playing = new EventEmitter();
  @Output() livchatStatus = new EventEmitter(); // Live Chat emitter
  liveChatCurrentVideo: any;
  video: any;
  interVal: any;
  subTitle: any = [];
  key: any;
  iv: any;
  decrypted: any;
  encrypted: any;
  plainText: any;
  cipherText: any;
  userAgent: any;
  showXrayList = false;
  showXrayViewAll = false;
  showXrayCastDetail = false;
  showXrayScenestab = false;
  xrayCastList = [];
  xrayCastViewAllList = [];
  xrayScenesList = [];
  xrayCastDetail: any;
  currentDecryptUser;
  userId;
  userName: any;
  videoPaymentInfo;
  sendProgressEvent = false;
  showXray = false;
  showEpisode = false;
  mouseOverXray = false;
  mouseOverEpisode = false;
  viewCountStatus: string;
  qualityText: any;
  speedText: any;
  currentTime: any;
  liveChatText: any;
  liveMessages: any[];
  livechatInputHeight: any;
  commentErrors: any[];

  commentMaxlengthError: Boolean = false;
  commentShortError: Boolean = false;
  adminPlaylistVideos: any = [];
  playlistVideosObject: any = [];
  adminPlaylistSlugSubscribe: any;
  adminPlaylistSlug: string;
  webSeriesSlug: string;
  notSubscribed: Boolean = false;
  notBought: Boolean = false;
  currentPlaylistVideo: any;
  openPlaylist: Boolean = false;
  youTubePlayer: any;
  youtube: Boolean = false;
  vimeo: Boolean = false;
  videoSources: Plyr.Source[];
  start_time: any = 0;
  end_time: any = 0;
  pause: any = false;
 
  @Input() playerStatus: Boolean = false;
  next_video: any = false;
  sprite_image: any;
  @HostListener('window:beforeunload', ['$event'])
  callPlayerApi($event) {
    if (this.video.currentTime && !this.video.is_live) {
      this.storeWatchedDuration();
      this.apiService.callPostAPI('v2/videoanalytics', { 'video_slug': this.video.slug, 'seconds': this.player.currentTime() }, environment.analyticsApiUrl)
        .subscribe();
      return false;
    } else if (this.video.is_live) {
      this.apiService.callPostAPI('v2/videoanalytics', { 'video_slug': this.video.slug, 'seconds': 1 }, environment.analyticsApiUrl)
        .subscribe();
      // return false;
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    const ev = event.target;
    if (ev.closest('.player_next_video') || ev.className === 'player_next_video') {
      this.nextVideo();
    }
    if (ev.className === 'playlist-subscribe-now-btn') {
      this.router.navigate(['/subscription']);
    }
    if (ev.className === 'play-next-video') {
      // this.video.next_episode_slug && this.video.next_episode_slug.slug
      console.log('cominging here',this.video.slug,this.webSeriesSlug)
      $('#videos').removeClass('play-error');
      $('.play-error-display').addClass('play-hidden');
      $('.play-modal-dialog-content').html('');
      this.router.navigate(['watch/', this.video.next_episode_slug.slug],{ queryParams: { webseries_id: this.webSeriesSlug } });
    }

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setCurrentResolution();
  }
  /* @HostListener('window:focus', ['$event'])
   onFocus(event: FocusEvent): void {
	console.log("window tab focus"); 
	const videoElement = document.getElementById('videos_html5_api');
	  if ((document as any).pictureInPictureElement === videoElement) {
    // Exit PiP mode
    (document as any).exitPictureInPicture()
      .then(() => {
        // PiP mode is successfully exited
        console.log('Exited Picture-in-Picture mode');
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error exiting Picture-in-Picture mode:', error);
      });
  }
	//location.reload();
    //this._location.back();
	//this.setPlayer(this.video);
	//this.setPlayerConfig();
	//this.incrementInterVal(); 
	  //this.player.reset();
	  //this.player.pause();
      //this.player.play(this.player.currentTime);    

   }
   @HostListener('window:blur', ['$event'])
   onBlur(event: FocusEvent): void {
	const videoElement = document.getElementById('videos');
    console.log("window tab blur"); 
	if ((document as any).pictureInPictureEnabled) {
		// Request Picture-in-Picture mode for the video
		(document as any).requestPictureInPicture()
		  .then(() => {
			// PiP mode is successfully entered
			console.log('Entered Picture-in-Picture mode');
		  })
		  .catch((error) => {
			// Handle any errors
			console.error('Error entering Picture-in-Picture mode:', error);
		  });
	  }

   }*/

  constructor(
    private _location: Location,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private playerService: PlayerService,
    private connectionService: ConnectionService,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private toaster: ToastrService
  ) {
    if(!$('.main-dark').html()){
      $("body").addClass("firstuser-live main-dark first-user-activated body-video-page");
    }
    // this.translate.get('PLAYER.QUALITY').subscribe(res => {
    //   this.qualityText = res;
    //   setTimeout(() => {
    //     document.getElementsByClassName(
    //       'qualityLangChanges'
    //     )[0].innerHTML = this.qualityText;
    //     document.getElementsByClassName(
    //       'qualityLangChange'
    //     )[0].innerHTML = this.qualityText;
    //   }, 500);
    // });
    // this.translate.get('PLAYER.SPEED').subscribe(res => {
    //   this.speedText = res;
    //   setTimeout(() => {
    //     document.getElementsByClassName(
    //       'speedLangChanges'
    //     )[0].innerHTML = this.speedText;
    //     document.getElementsByClassName(
    //       'play-control-text'
    //     )[10].innerHTML = this.speedText;
    //   }, 200);
    // });

    this.key = CryptoJS.enc.Utf8.parse(environment.crypto_key);
    this.iv = CryptoJS.enc.Utf8.parse(environment.crypto_iv);
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = 'ONLINE';
      } else {
        this.status = 'OFFLINE';
        this.newtworkStatus = document.getElementById('online-toast');
        document.getElementById('videos').appendChild(this.newtworkStatus);
      }
    });
    this.formatAMPM(new Date());
    this.user = this.authService.decryption(localStorage.getItem('currentUser')); // getting current user value
    /** Get admin playlist id query param */
    this.route.queryParams
      .filter(params => params.playlist_id)
      .subscribe(params => {
        this.adminPlaylistSlug = params.playlist_id;
      });

    /** Get webseries id query param */
    this.route.queryParams
      .filter(params => params.webseries_id)
      .subscribe(params => {
        this.webSeriesSlug = params.webseries_id;
      });
    // document.getElementById('live-chat-comment').addEventListener('input', function () {
    //   console.log('input event fired');
    // }, false);'
    (<HTMLElement>document.querySelector('.min-content')).style.paddingTop = '0px';
  }

  // Get Current time
  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    this.currentTime = strTime;
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.remove('audio-started');
    this.currentDecryptUser = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    this.userId = this.currentDecryptUser.response.id;
    this.userName = this.currentDecryptUser.response.email.split('@')[0];
    if (this.userId === undefined) {
      this.apiService.callGetAPI(`v2/video/get_user_id`).subscribe(data => {
        this.userId = data['response'].user_id;
        this.userName = data['response'].email.split('@')[0];
      });
    }
    this.route.params.subscribe(params => {
      this.params = params;
      this.initialFunction();
    });
    this.togglePlaylist();
  }
  obtainCastData(videoSlug) {
    this.apiService
      .callGetAPI(`v2/video/cast/${videoSlug}`)
      .subscribe(data => {
        this.xrayCastList = data['response'].cast_info;
        if (this.xrayCastList.length > 0) {
          this.showXray = true;
        }
      });
  }
  ngAfterViewInit() {
    if (this.player !== undefined) {
      /** Load Playlist view once the container exists */
      this.player.playlist(
        this.playlistVideosObject
      );
      this.player.playlist.autoadvance(0);
      this.player.playlistUi();
    }
    this.translate.get('PLAYER.UP_NEXT').subscribe(res => {
      $('.vjs-up-next-text').text(res);
      $('.vjs-up-next-text').prop('title', res);
    });
    this.translate.get('PLAYER.NOW_PLAYING').subscribe(res => {
      $('.vjs-playlist-now-playing-text').text(res);
      $('.vjs-playlist-now-playing-text').prop('title', res);
    });
    if (this.video.is_live) {
      this.setCurrentResolution();
    }
    this.addWaterMark();
    if (this.video.is_embed) {
      this.youtubeplayerConfig();
    }
  }

  addNextBtn() {
    if (this.next_video) {
      $('.settings-group').append('<div class="player_next_video"><button class="show_next"><span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 439.357 439.357" style="enable-background:new 0 0 439.357 439.357;" xml:space="preserve"><g><path d="M360.427,5.833c-3.613-3.617-7.898-5.424-12.847-5.424h-36.545c-4.945,0-9.233,1.807-12.847,5.424   c-3.614,3.621-5.428,7.902-5.428,12.85v193.574c-0.948-2.091-2.19-3.901-3.717-5.424L86.343,4.125   c-3.617-3.617-6.663-4.856-9.136-3.713c-2.474,1.143-3.711,4.189-3.711,9.137v420.262c0,4.948,1.237,7.994,3.711,9.138   c2.473,1.14,5.519-0.1,9.136-3.724L289.048,232.52c1.529-1.711,2.765-3.521,3.72-5.424V420.66c0,4.948,1.81,9.239,5.421,12.847   c3.62,3.62,7.904,5.428,12.847,5.428h36.552c4.948,0,9.236-1.808,12.847-5.428c3.614-3.607,5.428-7.898,5.428-12.847V18.683   C365.854,13.73,364.037,9.45,360.427,5.833z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></span></button></div>');
      this.translate.get('PLAYER.NEXT_PART').subscribe(res => {
        $('.player_next_video span').text(res);
      });
    } else {
      $('.player_next_video').remove();
    }
  }

  xrayListClicked() {
    this.showXrayList = !this.showXrayList;
  }
  showXrayCastDetailMethod(cast) {
    const a = document.createElement('a');
    if (cast.external_url !== null && cast.external_url !== undefined) {
      a.href = cast.external_url;
      a.setAttribute('target', '_blank');
      this.player.pause();
      a.click();
      this.showXrayList = false;
    }
  }

  xrayCastDetailBackClicked() {
    this.showXrayViewAll = true;
    this.showXrayCastDetail = false;
  }

  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }
  binaryToHex(num) {
    let str = '',
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
    // return parseInt(num, 2).toString(16);
  }
  d2h(d) {
    return d.toString(16);
  }
  decrypt() {
    if (typeof this.video.passphrase !== 'undefined') {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(this.video.passphrase)
      });
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        CryptoJS.enc.Hex.parse(environment.crypto_key),
        {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC
        }
      );
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      this.incrementInterVal();
    }
  }
  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC
      }
    );
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }
  incrementInterVal() {
    this.interVal = setInterval(
      function () {
        this.plainText += 1;
      }.bind(this),
      1000
    );
    this.setPlayerConfig();
	
  }
  setPlayerConfig() {
    const getVideo = document.getElementById('videos');
    videojs.Hls.xhr.beforeRequest = options => {
      options.headers = [];
      if (this.video.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.userAgent = window.navigator.userAgent;
    if (this.userAgent.match(/iPad/i) || this.userAgent.match(/iPhone/i)) {
      this.player = videojs(getVideo, {
        playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
        controls: true,
		//responsive: true,
		controlBar: {
		  pictureInPictureToggle: true,
		  progressControl: {
			seekBar: true
		  },
		},
        preload: 'metadata',
		loadingSpinner: true,
        fluid: true,
        autoplay: false,
		liveui: true,
		limitRenditionByPlayerDimensions:false,
        seekEventPercentage: this.video.seekEventPercentage || 30,
        tracks: this.setTracks(),
         plugins: {
          hlsQualitySelector: {},
          keyboardShortCuts: {},
          spriteThumbnails: {}
        }
      });
    } else {
      this.player = videojs(getVideo, {
        playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
        controls: true,
		controlBar: {
		  pictureInPictureToggle: true,
		  progressControl: {
			seekBar: true
		  },
		},
        preload: 'metadata',
        fluid: true,
        autoplay: false,
		loadingSpinner: true,
		liveui: true,
        seekEventPercentage: this.video.seekEventPercentage || 30,
         html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          nativeTextTracks: false,
		  
          hls: {
			withCredentials: false,
            overrideNative: true,
			smoothQualityChange: true,
			enableLowInitialPlaylist:true,
			limitRenditionByPlayerDimensions: false,
            customDrm: {
              // tslint:disable-next-line:max-line-length
              keys:'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
              // 'uri': 'http://d1xxzl3j6dvosn.cloudfront.net/costarica/encrypted.key',
            }
          }
        },
        tracks: this.setTracks(),
        plugins: {
          hlsQualitySelector: {},
          seekButtons: {
            forward: 10,
            back: 10
          },
          keyboardShortCuts: {},
          spriteThumbnails: {}
        }
      });
    }
    // Need to comment below to deactivate advertisement
    const options = {
      id: 'player',
      adTagUrl: this.video.ads_url,
      debug: false,
      adLabel: 'Video resumes in'
    };

    if (this.video.ads_url) {
      this.player.ima(options);
    }
    this.player.on('adsready', () => {
      this.next_video = false;
      this.addNextBtn();
      this.player.play();
    });

    this.player.on('adend', () => {
      this.next_video = (this.video.next_episode_slug !== '' && this.video.next_episode_slug !== null) ? true : false;
      this.addNextBtn();
    });


    this.player.on('adserror', () => {
      this.player.play();
    });
    this.setPlayer(this.video);
	
  }
  setTracks() {
    if (this.video.subtitle) {
      if (this.video.subtitle.subtitle_list.length > 0) {
        this.video.subtitle.subtitle_list.forEach(element => {
          this.subTitle.push({
            title: 'subtitles',
            kind: 'subtitles',
            language: element.language,
            label: element.label,
            src: this.video.subtitle.base_url + element.url,
            default: element.default
          });
        });
        this.subTitle.push({});
      }
      return this.subTitle;
    } else {
      return [];
    }
  }
  setPlayer(video) {
    $('#videos').removeClass('play-error');
    $('.play-error-display').addClass('play-hidden');
    $('.play-modal-dialog-content').html('');
    this.video = video;
    this.newtworkStatus = document.getElementById('online-toast');
    document.getElementsByClassName('play-text-track-display')[0].innerHTML =
      '<div>' + video.title + '</div>';
    if (this.newtworkStatus !== null) {
      document.getElementById('videos').appendChild(this.newtworkStatus);
    }
    $('#ogImage').attr('content', video.poster_image);
    $('.video-cs-blk').appendTo($('#videos'));
    $('.next-video').appendTo($('#videos'));
    this.player.on('play', () => {
      let self = this;
    // setTimeout(
    //   function () {
        if ((video.price > 0 || video.is_premium !== 0) && video.slug === this.params.video_id) {
          let userValues = localStorage.getItem('currentUser');
          if (userValues) {
            let userData = self.authService.decryption(userValues);
            console.log("userData['response']",userData['response']);
            if (userData['response'].is_subscribed == 0) {
              if (self.webSeriesSlug) {
                self.player.pause();
                setTimeout(
                  function () {
                    let next_part,subscribe_to_watch,subscribe_now;
                    self.translate.get('PLAYER.NEXT_PART').subscribe(res => {
                      next_part = res;
                    });
                    self.translate.get('VIDEO_DETAIL.PREMIUM_ACCOUNT').subscribe(res => {
                      subscribe_to_watch = res;
                    });
                    self.translate.get('SUBSCRIPTION.SUBSCRIBE_NOW').subscribe(res => {
                      subscribe_now = res;
                    });
                    $('#videos').addClass('play-error');
                    $('.play-error-display').removeClass('play-hidden');
                    if (video.next_episode_slug && video.next_episode_slug.slug) {
                      $('.play-modal-dialog-content').html('<div class="playlist-subscription popup"><div>'+subscribe_to_watch+'</div><div><div style="  margin: 20px;"><a class="playlist-subscribe-now-btn" style="width: 100%;padding: 10px;background: linear-gradient(to right, #FB8541 0%, #f16c1f 100%);border-radius: 3px;cursor: pointer;" ui-sref="subscribeinfo" title="'+subscribe_now+'">'+subscribe_now+'</a></div><div><a class="play-next-video" style="width: 100%;cursor: pointer;color: #f27125;text-decoration: underline;">'+next_part+'</a></div></div></div>');
                    } else {
                      $('.play-modal-dialog-content').html('<div class="playlist-subscription popup"><div>'+subscribe_to_watch+'</div><div><div style="  margin: 20px;"><a class="playlist-subscribe-now-btn" style="width: 100%;padding: 10px;background: linear-gradient(to right, #FB8541 0%, #f16c1f 100%);border-radius: 3px;cursor: pointer;" ui-sref="subscribeinfo" title="'+subscribe_now+'">'+subscribe_now+'</a></div></div></div>');
                    }
                }, 500);
              } else {
                self.router.navigate(['video/' + video.slug]);
              }
            } else {
              $('#videos').removeClass('play-error');
              $('.play-error-display').addClass('play-hidden');
              $('.play-modal-dialog-content').html('');
            }
          } else {
            $('#videos').removeClass('play-error');
            $('.play-error-display').addClass('play-hidden');
            $('.play-modal-dialog-content').html('');
          }
        } else {
            $('#videos').removeClass('play-error');
            $('.play-error-display').addClass('play-hidden');
            $('.play-modal-dialog-content').html('');
        }
      // }, 1000);
      //  const socket = new WebSocket(environment.socketUrl);
      //   socket.onopen = (event) => {
      //     socket.send('1');
      //   };
    });
    if (!video.ads_url) {
      this.player.play();
    }
    // this.player.autoplay(true);
    if (this.adminPlaylistVideos === null) {
      this.player.src({
        src: video.hls_playlist_url,
        type: 'application/x-mpegURL'
      });
    } else {
      let videoplaylistStatus;
      if (this.video.is_premium) {
        videoplaylistStatus = 'premium';
      } else if (this.video.price > 0) {
        videoplaylistStatus = 'tvod';
      }
      this.playlistVideosObject.push({
        name: video.title,
        description: video.description,
        sources: [{
          src: video.hls_playlist_url,
          type: 'application/x-mpegURL'
        }],
        thumbnail: [
          {
            src: video.thumbnail_image,

          }
        ],
        slug: video.slug,
        price: video.price,
        is_premium: video.is_premium,
        is_subscribed: video.is_subscribed,
        video_transaction: this.videoPaymentInfo.transaction_id,
        status: videoplaylistStatus,
        is_restricted: video.is_restricted
      });
      this.adminPlaylistVideos.forEach((element) => {
        if (element.price > 0 || element.is_premium !== 0) {
          if ((element.is_premium && element.is_subscribed === 0)
            || (element.price > 0 && (element.video_payment_transactions === null || (element.video_payment_transactions && element.video_payment_transactions.status === 'Expired')))
          ) {
            element.status = (element.is_premium) ? 'premium' : 'tvod';
          }
        }
        this.playlistVideosObject.push({
          name: element.title,
          description: element.description,
          sources: [{
            src: element.hls_playlist_url,
            type: 'application/x-mpegURL'
          }],
          thumbnail: [
            {
              src: element.thumbnail_image,
            }
          ],
          slug: element.slug,
          price: element.price,
          is_premium: element.is_premium,
          is_subscribed: element.is_subscribed,
          status: element.status,
          video_transaction: element.video_payment_transactions,
          is_restricted: element.is_restricted
        });
      });

      this.player.on(['playlistitem'], function (e) {
        const currentPlaylistIndex = this.player.playlist.currentItem();
        const currentPlaylistData = this.playlistVideosObject[currentPlaylistIndex];
        this.currentPlaylistVideo = currentPlaylistData;
        this.video.title = currentPlaylistData.name;
        const videoslug = currentPlaylistData.slug;
        if (currentPlaylistData.is_restricted === 1) {
          this.geoStatus = true;
          this.player.src('');
          this.notSubscribed = false;
          this.notBought = false;
        }
        localStorage.setItem('adminPlaylistUrl', JSON.stringify(['watch/' + videoslug, this.adminPlaylistSlug]));
        this.router.navigate(['watch/' + videoslug], { queryParams: { playlist_id: this.adminPlaylistSlug } });
        this.obtainCastData(currentPlaylistData.slug);

        if (currentPlaylistData.is_restricted === 1) {
          this.geoStatus = true;
          this.player.src('');
          this.notSubscribed = false;
          this.notBought = false;
        } else if (currentPlaylistData.price > 0 || currentPlaylistData.is_premium !== 0) {
          if (currentPlaylistData.is_subscribed === 0
            && (currentPlaylistData.video_transaction === null
              || currentPlaylistData.video_transaction.status === 'Expired'
            )
          ) {
            this.player.src('');
            if (currentPlaylistData.status === 'premium') {
              this.notSubscribed = true;
            } else if (currentPlaylistData.status === 'tvod') {
              this.notBought = true;
            }
          }
        } else {
          this.notSubscribed = false;
          this.notBought = false;
        }
      }.bind(this));
    }
    this.player.currentTime(this.video.seconds ? this.video.seconds : 0);
    this.start_time = this.video.seconds ? this.video.seconds : 0;
    /** Check last video session */
    // const session = JSON.parse(localStorage.getItem('videos'));
    // if (session && typeof session['hls_playlist_url'] !== 'undefined' &&
    //   typeof video.hls_playlist_url !== 'undefined' &&
    //   session['hls_playlist_url'] === video.hls_playlist_url
    // ) {
    //   this.player.currentTime(session['currentTime']);
    // } else {
    //   this.player.currentTime(0);
    // }
    this.player.on('pause', () => {
      // const socket = new WebSocket(environment.socketUrl);
      // socket.onopen = (event) => {
      //   socket.send('0');
      // };
    });
    /** Video playing */
    this.player.on('ready', event => {
      //console.log('ready 681');
    });
    this.player.on('playing', () => {
      if (!this.isPlaying) {
        // if (this.currentDecryptUser) {
        //   this.apiService.personalizePutEvent(this.params.video_id, `${this.currentDecryptUser.response.id}`);
        // }
        this.isPlaying = true;
        this.playing.emit(false);
        this.player.spriteThumbnails({
          url: this.sprite_image,
          width: 192,
          height: 113,
          stepTime: 20
        });
      }
    });
    setInterval(
      function () {
        if (!this.video.is_vimeo && !this.video.is_embed) {
          if ($('.play-control-bar').css('opacity') === '1') {
            $('.back_btn').show();
            $('.movie-title').show();
            this.showXray = true;
            this.showEpisode = true;
          } else {
            $('.back_btn').hide();
            $('.movie-title').hide();
            this.showXray = this.mouseOverXray ? true : false;
            this.showEpisode = this.mouseOverEpisode ? true : false;
          }
        }
      }.bind(this),
      1
    );
    /** Video current time update */
    this.player.on('timeupdate', async () => {
      if (this.player.currentTime() === this.player.duration()) {
        if (this.video.next_episode_slug !== '' && this.video.next_episode_slug !== null) {
          this.getCompleteVideoDetail();
        }
      }
      if ((this.end_time > this.player.currentTime()) || ((this.end_time + 5) < this.player.currentTime())) {
        await this.storeWatchedDuration();
        this.start_time = this.end_time = this.player.currentTime();
      } else {
        this.end_time = this.player.currentTime();
      }
      this.time = this.player.currentTime();
      video.currentTime = this.time;
      localStorage.setItem('videos', JSON.stringify(video));
    });

    this.player.on('modelClose', () => {
      $('.video-player-center').removeClass('rem-name');
      $('.video-cs-blk').show();
    });
    this.player.on('modelOpen', () => {
      $('.video-player-center').addClass('rem-name');
      $('.video-cs-blk').hide();
    });
    /** Video Error */
    this.player.on('error', event => {
	debugger;
      if (
        this.player.error().status === 403 ||
        this.player.error().status === 404 ||
        this.player.error().code === 4
      ) {
        if (video.is_live === 1) {
          $('.play-error-display').removeClass('play-hidden');
          let getMessage;
          this.translate.get('PLAYER.LIVE_NOT_STARTED').subscribe(res => {
            getMessage = res;
          });
          $('.play-modal-dialog-content').html(getMessage);
          this.player.reset();
        } else {
          if (this.adminPlaylistSlug && this.video.is_premium === 1) {
            $('.play-modal-dialog-content').html('<div class="playlist-subscription popup"><div>subscribe to watch the video</div><div><div style="  margin: 20px;"><a class="playlist-subscribe-now-btn" style="width: 100%;padding: 10px;background: linear-gradient(to right, #FB8541 0%, #f16c1f 100%);border-radius: 3px;cursor: pointer;" ui-sref="subscribeinfo" title="subscribe now">subscribe now</a></div></div></div>');
            // <div><a class="play-next-video" style="width: 100%;cursor: pointer;color: #f27125;text-decoration: underline;">Play Next Video</a></div>
            this.player.reset();
            // $('.play-error-display').removeClass('play-hidden');
            // let getMessage;
            // this.translate.get('PLAYER.VIDEO_NOT_FOUND').subscribe(res => {
            //   getMessage = res;
            // });
            // $('.play-modal-dialog-content').html(getMessage);
            // this.player.reset();
          } else if(this.next_video){
            $('.play-modal-dialog-content').html('<div class="playlist-subscription popup"><div>subscribe to watch the video</div><div><div style="  margin: 20px;"><a class="playlist-subscribe-now-btn" style="width: 100%;padding: 10px;background: linear-gradient(to right, #FB8541 0%, #f16c1f 100%);border-radius: 3px;cursor: pointer;" ui-sref="subscribeinfo" title="subscribe now">subscribe now</a></div><div><a class="play-next-video" style="width: 100%;cursor: pointer;color: #f27125;text-decoration: underline;"></a></div></div></div>');
            // this.player.reset();
          } else {
            $('.play-error-display').removeClass('play-hidden');
            let getMessage;
            this.translate.get('PLAYER.VIDEO_NOT_FOUND').subscribe(res => {
              getMessage = res;
            });
            $('.play-modal-dialog-content').html(getMessage);
            this.player.reset();
          }
        }
      }
      if (this.player.error().code === 2) {
        if (video.is_live === 1) {
          $('.play-error-display').removeClass('play-hidden');
          let getMessage;
          this.translate.get('PLAYER.LIVE_NOT_STARTED').subscribe(res => {
            getMessage = res;
          });
          $('.play-modal-dialog-content').html(getMessage);
          this.player.reset();
        } else {
          $('.play-error-display').removeClass('play-hidden');
          let getMessage;
          this.translate.get('PLAYER.VIDEO_NOT_FOUND').subscribe(res => {
            getMessage = res;
          });
          $('.play-modal-dialog-content').html(getMessage);
          this.player.reset();
        }
      }
      if (this.player.error().code === 3) {
        $('.play-error-display').removeClass('play-hidden');
        let getMessage;
        this.translate.get('PLAYER.STREAM_NOT_SUPPORTED').subscribe(res => {
          getMessage = res;
        });
        $('.play-modal-dialog-content').html(getMessage);
        this.player.reset();
      }
      if (this.player.error().code === 5) {
        $('.play-error-display').removeClass('play-hidden');
        let getMessage;
        this.translate.get('PLAYER.WENT_WRONG').subscribe(res => {
          getMessage = res;
        });
        $('.play-modal-dialog-content').html(getMessage);
        this.player.reset();
      }
    });

    /** Video End */
    this.player.on('ended', () => {
      this.isPlaying = false;
      video.currentTime = 0;
      this.storeWatchedDuration(true);
      this.apiService.callPostAPI('v2/videoanalytics', { 'video_slug': this.video.slug, 'seconds': 0 }, environment.analyticsApiUrl)
        .subscribe();
    });

    this.player.on('progressWidth', e => {
	debugger
      if (this.viewCountStatus === 'Expired') {
        if (e.progressFinal > 99.9) {
          if (this.adminPlaylistSlug) {
            this.router.navigate(['watch/' + this.currentPlaylistVideo.slug], { queryParams: { playlist_id: this.adminPlaylistSlug } });
          } else {
            this.router.navigate([`/video/${this.video.slug}`]);
          }
        }
      } else {
        if (e.progressFinal >= 10 && e.progressFinal <= 30) {
          if (!this.sendProgressEvent) {
            this.sendProgressEvent = true;
            this.callTvodViewCount(50);
          }
        } else if (e.progressFinal >= 60 && e.progressFinal <= 80) {
          if (!this.sendProgressEvent) {
            this.sendProgressEvent = true;
            this.callTvodViewCount(100);
          }
        } else {
          this.sendProgressEvent = false;
        }
      }
    });
	/* this.player.tech().on('usage', event => {
	debugger
      if (event.name === 'hls-live-resync') {
        console.log('hls-live-resync');
      }
      if (event.name === 'hls-unknown-waiting') {
        console.log('hls-unknown-waiting');
      }
      if (event.name === 'hls-error-reload-initialized') {
        console.log('hls-error-reload-initialized');
      }
      if (event.name === 'hls-error-reload') {
        console.log('hls-error-reload');
      }
      
    }); */

    // this.player.on('progressEvent', () => {
    //   if (!this.sendProgressEvent) {
    //     this.sendProgressEvent = true;
    //     this.apiService.callGetAPI(`tvod_view_count/${this.video.transaction_id}`).subscribe(data => {
    //     });
    //   }
    // })
  }

  callTvodViewCount(completePercent) {
    if (this.video && this.video.price > 0) {
      this.apiService
        .callPostAPI('v2/tvod_view_count', {
          transaction_id: this.videoPaymentInfo.transaction_id,
          complete_percentage: completePercent
        })
        .subscribe(data => {
          if (data.hasOwnProperty('response')) {
            this.viewCountStatus = data['response'].status;
          }
        });
    }
  }

  goToSubscription() {
    localStorage.setItem('subscribeUrl', window.location.pathname);
    this.router.navigate(['/subscription']);
  }

  ngOnDestroy() {
    document.getElementsByTagName('body')[0].classList.remove('body-video-page');
    if (this.video.currentTime && !this.video.is_live) {
      this.storeWatchedDuration();
      let videoSeconds = this.video.is_embed ? this.newYoutube.getCurrentTime() : this.player.currentTime();
      this.apiService.callPostAPI('v2/videoanalytics', { 'video_slug': this.video.slug, 'seconds': videoSeconds }, environment.analyticsApiUrl)
        .subscribe();
      if (this.player) {
        this.player.dispose();
      }
    } else if (this.video.is_live) {
      this.apiService.callPostAPI('v2/videoanalytics', { 'video_slug': this.video.slug, 'seconds': 1 }, environment.analyticsApiUrl)
        .subscribe();
      return false;
    } else {
      if (this.player) {
        this.player.dispose();
      }
    }
    if (this.video.is_live && parseInt(this.video.is_embed) === 1) {
      // (<HTMLElement>document.querySelector('.min-content')).style.paddingTop = '80px';
    }
    this.openPlaylist = false;
  }

  PageBack() {
    if (this.adminPlaylistSlug) {
      this.router.navigate(['playlist/' + this.adminPlaylistSlug]);
    } else {
      this._location.back();
    }
  }

  // Live chat configuration
  /**
   * live chat tab open
  */
  toggleLiveChat(status) {
    this.livchatStatus.emit(status);
  }
  /**
   * Get Video id based on the video slug
  */
  getVideoId() {
    const url = 'v2/getVideoId/';
    this.apiService.callGetAPI(url + this.video.slug).subscribe(response => {
      this.liveChatCurrentVideo = response['response'];

    },
      error => {
        console.log(error);
      });
  }



  /**
   * Send live messages
   */

  onKeydown(event) {
    event.preventDefault();
  }
  trackByFn(index, item) {
    return index; // or item.id
  }
  changeEvent(change) {
    if (change && !(change.trim() === '')) {
      this.commentShortError = false;
    }
    if (change && change.length > 200) {
      this.commentMaxlengthError = true;
    } else {
      this.commentMaxlengthError = false;
    }
    this.livechatInputHeight = document.getElementById('live-chat-input').offsetHeight;
    this.livechatInputHeight = this.livechatInputHeight + 70;
    this.livechatInputHeight = 'calc(100% - ' + this.livechatInputHeight + 'px)';
  }
  videoClick(video) {
    this.router.navigate(['video/' + video.slug]);
  }
  togglePlaylist() {
    if (this.openPlaylist) {
      document.body.classList.add('close-playlistvideo');
      this.openPlaylist = false;
    } else {
      document.body.classList.remove('close-playlistvideo');
      this.openPlaylist = true;
    }
  }

  getCompleteVideoDetail() {
    this.router.navigate(['watch/' + this.video.next_episode_slug.slug],{ queryParams: { webseries_id: this.webSeriesSlug } });
  }

  initialFunction() {
  console.log('initialFunction-1001', this.video);
    this.playerService.videoPaymentInfo.subscribe(data => {
      this.videoPaymentInfo = data;
    });
    this.playerService.currVideo.subscribe(data => {
      if (!data.is_live && !data.is_embed) {
        this.youtube = false;
        if (data !== '' && this.setVideo && data.hls_playlist_url) {
          this.setVideo = true;
          this.video = data;
          if (this.video.playlist_videos) {
            this.adminPlaylistVideos = this.video.playlist_videos;
          } else {
            this.adminPlaylistVideos = null;
          }
          this.next_video = (this.video.next_episode_slug !== '' && this.video.next_episode_slug !== null && this.video.ads_url === '') ? true : false;
          this.sprite_image = (this.video.next_episode_slug !== '' && this.video.next_episode_slug !== null) ? null : data.sprite_image;
          if (this.video) { // Get video id based on vide slug for live chat configuration
            this.getVideoId();
          }
          if (this.video.is_restricted && this.adminPlaylistSlug !== null) {
            this.geoStatus = true;
            this.setPlayerConfig();
          } else if (!this.video.is_restricted) {
            this.geoStatus = false;
            this.decrypt();
          }
          this.addNextBtn();
        }
      } else {
        if (parseInt(data.is_embed) === 1) {
          $('.back_btn').show();
          $('.movie-title').show();
          document.querySelector('#videos').remove();
          this.youtube = true;
          this.setVideo = true;
          this.video = data;
          // if(typeof this.video.embed_url  === 'string' ||  this.video.embed_url instanceof String){
          //   this.video.embed_url = this.getSafeUrl(`https://www.youtube.com/embed/${this.video.embed_url}/?modestbranding=1&rel=0&showinfo=0&autoplay=1`);
          // }
          console.log('video-1040', this.video);
          this.adminPlaylistVideos = null;
          this.next_video = null;
          this.getVideoId();
          $('.video-cs-blk').addClass('youtube');

          this.videoSources = [
            {
              src: this.video.embed_url, // 'Uw7xmfeL8gI', // 'l4xqmDVi7oc',
              provider: 'youtube',
            },
          ];

        } else if (parseInt(data.is_vimeo) === 1) {
          $('.back_btn').show();
          $('.movie-title').show();
          // data.embed_url = this.getSafeUrl(data.embed_url);
          $('.video-player-center').removeClass('rem-name');
          $('.video-cs-blk').show();
          this.vimeo = true;
          this.setVideo = true;
          this.video = data;
          if (typeof this.video.embed_url === 'string' || this.video.embed_url instanceof String) {
            this.video.embed_url = this.getSafeUrl(this.video.embed_url);
          }
          console.log('video', this.video);
          this.adminPlaylistVideos = null;
          this.next_video = null;
          this.getVideoId();
          $('.video-cs-blk').addClass('vimeo');
        }

      }
    });

    document.getElementsByTagName('body')[0].classList.add('body-video-page');
    if (!this.geoStatus) {
      this.obtainCastData(this.video.slug);
    }
  }
  played(event: Plyr.PlyrEvent) {
    const self = this.youTubePlayer;
    const globelSelf = this;
    this.youTubePlayer.play();
    this.youTubePlayer.on('ready', event => {
      self.youtube = { autoplay: 1, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 };
      console.log('ready');
      self.play();
    });
    console.log('youTubePlayer', event);
    this.youTubePlayer.on('pause', function (e) {
      console.log('pause', self.fullscreen.active);
      globelSelf.pause = true;
      (<HTMLElement>document.querySelector('.video-cs-blk')).style.display = 'flex';
      (<HTMLElement>document.querySelector('.back_btn')).style.display = 'block';
      // (<HTMLElement>document.querySelector('.movie-title')).style.display = 'block';
      (<HTMLElement>document.querySelector('#poster-img')).style.display = 'block';
    });
    this.youTubePlayer.on('playing', function (e) {
      console.log('play');
      globelSelf.pause = false;
      (<HTMLElement>document.querySelector('#poster-img')).style.display = 'none';
    });
    this.youTubePlayer.on('enterfullscreen', function (e) {
      console.log('loading');
      self.fullscreen.exit();
    });
    this.youTubePlayer.on('progress', function (e) {
      console.log('loading');
      // (<HTMLElement>document.querySelector('#poster-img')).style.display ='block';
    });
  }
  playYouTube() {
    // this.youTubePlayer.play();
    this.newYoutube.play()
    const globelSelf = this;
    setTimeout(function () {
      if (!globelSelf.pause) {
        // $('.video-cs-blk').css('display', 'none');
        // $('.back_btn').css('display', 'none');
        // $('.movie-title').css('display', 'none');
      }
    }, 8000);
  }
  setCurrentResolution() {
    // if (document.getElementById('youtube-player')) {
    //   (<HTMLElement>document.querySelector('#youtube-player')).style.width = window.innerWidth + 'px';
    //   (<HTMLElement>document.querySelector('#youtube-player')).style.height = window.innerHeight + 'px';
    // }
  }

  nextVideo() {
    this.getCompleteVideoDetail();
  }
  storeWatchedDuration(ended = false) {
    if (this.start_time < this.end_time) {
      this.apiService.callPostAPI('v2/watched-duration', { 'start_time': this.start_time, 'end_time': this.end_time }, environment.analyticsApiUrl)
        .subscribe();
    }
    if (ended) {
      this.start_time = this.end_time = 0;
    }
  }

  addWaterMark() {
    if ($('.play-text-track-display') && !this.video.is_vimeo && !this.video.is_embed) {
      // let watermart = `<div style='color: rgba(195,195,195,.2);position: absolute;bottom: 20px;left: 45%;font-size: 20px;'>${this.userName}</div>`;
      let watermark = `<div class="vplayer-overlay is--active"><p>${this.userName}</p></div><div class="watermark-logo"><img src="./../../../../assets/images/player-watermark.png" /></div>
      <div class="watermark-logo2"><img src="./../../../../assets/images/player-watermark.png" /></div>`
      $(watermark).insertAfter('.play-text-track-display');
    }
  }

  youtubeplayerConfig() {
    this.newYoutube = new YTPlayer('#ytplayer', { modestBranding: 1, rel: 0, showinfo: 0, autoplay: 1, origin: environment.frontend })
    this.newYoutube.load(this.video.embed_url, [true, [this.video.seconds]])
    // $('#ytplayer').attr("src", `https://www.youtube.com/embed/${this.video.embed_url}/?modestbranding=1&rel=0&showinfo=0&autoplay=1&origin=${environment.frontend}`);
    // this.newYoutube.setVolume(100)
    this.newYoutube.play()

    this.newYoutube.on('unstarted', () => { console.log('unstarted') })
    this.newYoutube.on('ended', () => {
      (<HTMLElement>document.querySelector('#poster-img')).style.display = 'block';
      this.isPlaying = false;
      this.video.currentTime = 0;
      this.storeWatchedDuration(true);
      this.apiService.callPostAPI('v2/videoanalytics', { 'video_slug': this.video.slug, 'seconds': 0 }, environment.analyticsApiUrl)
        .subscribe();
    })
    var setseconds = false;
    this.newYoutube.on('playing', () => {
      if (this.newYoutube.getCurrentTime() < this.video.seconds && !setseconds) {
        this.newYoutube.seek(this.video.seconds)
        setseconds = true;
      }
      setTimeout(function () {
        (<HTMLElement>document.querySelector('#poster-img')).style.display = 'none';
      }, 1000);
    })
    this.newYoutube.on('paused', () => {
      (<HTMLElement>document.querySelector('#poster-img')).style.display = 'block';
    })
    this.newYoutube.on('buffering', () => { console.log('buffering') })
    this.newYoutube.on('cued', () => { console.log('cued') })

    this.newYoutube.on('error', (err) => {
      console.log('err', err);
    })

    this.newYoutube.on('timeupdate', async (seconds) => {
      if (this.newYoutube.getCurrentTime() === this.newYoutube.getDuration()) {
        if (this.video.next_episode_slug !== '' && this.video.next_episode_slug !== null) {
          this.getCompleteVideoDetail();
        }
      }
      if ((this.end_time > this.newYoutube.getCurrentTime()) || ((this.end_time + 5) < this.newYoutube.getCurrentTime())) {
        await this.storeWatchedDuration();
        this.start_time = this.end_time = this.newYoutube.getCurrentTime();
      } else {
        this.end_time = this.newYoutube.getCurrentTime();
      }
      this.time = this.newYoutube.getCurrentTime();
      this.video.currentTime = this.time;
      localStorage.setItem('videos', JSON.stringify(this.video));
    })
  }

}
