import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }
  private popup = new BehaviorSubject<any>('close');
  display = this.popup.asObservable();

  // playlist popup
  displayPopup(data: any) {
    this.popup.next(data);
  }
}
