export const environment = {
  production: true,

 // userApiUrl: 'https://api.kripalunidhi.org/users/api/',
 // apiUrl: 'https://api.kripalunidhi.org/medias/api/',
 // userActionApiUrl: 'https://api.kripalunidhi.org/useractions/api/',
 // paymentApiUrl: 'https://api.kripalunidhi.org/payments/api/',
 // notificationApiUrl: 'https://api.kripalunidhi.org/notifications/api/',
 // commonApiUrl: 'https://api.kripalunidhi.org/common/api/',
 // searchApiUrl: 'https://api.kripalunidhi.org/search/elastic/',
  // analyticsApiUrl: 'https://api.kripalunidhi.org/analytics/api/',
   userApiUrl: 'https://staging.kripalunidhi.org/users/api/',
   apiUrl: 'https://staging.kripalunidhi.org/medias/api/',
   userActionApiUrl: 'https://staging.kripalunidhi.org/useractions/api/',
   paymentApiUrl: 'https://staging.kripalunidhi.org/payments/api/',
   notificationApiUrl: 'https://staging.kripalunidhi.org/notifications/api/',
   commonApiUrl: 'https://staging.kripalunidhi.org/common/api/',
   searchApiUrl: 'https://staging.kripalunidhi.org/search/elastic/',
   analyticsApiUrl: 'https://staging.kripalunidhi.org/analytics/api/',
  frontend: 'https://staging.kripalunidhi.org/',
  // userApiUrl: 'http://127.0.0.1:8003/users/api/',
  // apiUrl: 'http://127.0.0.1:8007/medias/api/',
  // userActionApiUrl: 'http://127.0.0.1:8004/useractions/api/',
  // paymentApiUrl: 'http://127.0.0.1:8005/payments/api/',
  // notificationApiUrl: 'http://127.0.0.1:8006/notifications/api/',
  // commonApiUrl: 'http://127.0.0.1:8008/common/api/',
  // searchApiUrl: 'http://127.0.0.1:8080/search/elastic/',
  //   // searchApiUrl: 'https://api.kripalunidhi.org/search/elastic/',
  // analyticsApiUrl: 'http://127.0.0.1:8009/analytics/api/',

  socketUrl: 'wss://admin.vplayed-jkp-uat.contus.us/ws/',
  encryption_key: 'vplay@contus',
  // GoogleOAuthClientId: '7673259642-47joo69p3s7emc849l23ciatg10r7m9l.apps.googleusercontent.com',
  GoogleOAuthClientId: '910251918072-duce28f40it1051v7iusicb8lc3hujpu.apps.googleusercontent.com',

  // GoogleOAuthClientId: '165831866280-vb8vna7kolj2mquj26c9v6ktchs81486.apps.googleusercontent.com',
  FacebookAppId: '443027636605973',
  googleRecaptcha: '6LekEMQUAAAAALOZuJIQRJbf8wJ9S0KFKQUFNF3A',
  googleMapKey: 'AIzaSyA4cI8fc5XyQAeGaDy7ZmVh7LSrMbTku98',
  latitude: 13.0156018,
  longitude: 80.1981568,
  defaultImage: 'assets/images/defaultloader.png',
  domain: 'https://staging.kripalunidhi.org/',
  crypto_iv: 'a32b97d84acba36667c7709de74fa510',
  crypto_key: '2f00f5fbea3c1c77e8d3e5536021fc1d46e803670879e01e9de64b387ea4dc68',
  AWS_accessKeyId: 'AKIAVBJWLCX4M37S7SPV',
  AWS_secretAccessKey: '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',
  AWS_region: 'us-east-1',
  AWS_personalize_trackingId: '848a144c-81bc-4d1c-ab84-bd7d509e8635',
  AWS_personalize_campaignArn: 'arn:aws:personalize:us-east-1:346395710968:campaign/vplayed-user-active-video-slug-dataset-campaign'
};
